import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CompanyService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    search(page: number = 1, model) {
        const url = `${this.BASE_URL}/company/search/${page}`;
        return this.http.post(url, model);
    }

    get(id: string) {
        const url = `${this.BASE_URL}/company/${id}`;
        return this.http.get(url);
    }

    getTags() {
        const url = `${this.BASE_URL}/company/list-tags`;
        return this.http.get(url);
    }

    create(model) {
        const url = `${this.BASE_URL}/company/create`;
        return this.http.post(url, model);
    }

    update(model, id) {
        const url = `${this.BASE_URL}/company/update/${id}`;
        return this.http.put(url, model);
    }

    delete(id: string, lang: string) {
        const url = `${this.BASE_URL}/company/${id}/${lang}`;
        return this.http.delete(url);
    }

    upload(file, id) {
        const url = `${this.BASE_URL}/company/${id}/upload`;
        return new Observable((observer) => {
            // tslint:disable-next-line:one-variable-per-declaration
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            if (file != null) {
                formData.append('file', file, file.name);
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
            xhr.send(formData);
        });
    }
}
