import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Settings} from '../../../settings';

declare var $: any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, AfterViewInit {

  version = Settings.VERSION;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.accordionEvents();
  }

  accordionEvents() {
    const element = $('.js-accordion');
    if (element[0]) {
      element.find('.accordion_item_question').on('click', 'a', function(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        $(e.currentTarget).parent().parent().siblings().removeClass('opened').find('.accordion_item_answer').stop().slideUp();
        $(e.currentTarget).parent().parent().toggleClass('opened');
        $(e.currentTarget).parent().parent().find('.accordion_item_answer').stop().slideToggle();
        return false;
      });
    }
  }

  onKey(value: string) {
    const fieldValue = value;
    let numShow = 0;

    $('.js-accordion').find('.accordion_item').each(function(e, i) {
      // console.log($(this).find('.name').text());
      if ($(this).find('.accordion_item_question_lbl').text().toLowerCase().indexOf(fieldValue.toLowerCase()) >= 0 ||
        $(this).find('.accordion_item_answer').text().toLowerCase().indexOf(fieldValue.toLowerCase()) >= 0) {
        $(this).removeClass('d-none');
        numShow++;
      } else {
        $(this).addClass('d-none');
      }
    });
    if (numShow === 0) {
      $('.accordion_item_notfound').find('strong').html(fieldValue);
      $('.accordion_item_notfound').removeClass('d-none');
    } else {
      $('.accordion_item_notfound').find('strong').html('');
      $('.accordion_item_notfound').addClass('d-none');
    }
  }


}

//
// }accordeon() {
//   const element = $('.js-accordion');
//   if (element[0]) {
//     element.find('.accordion__item--question').on('click', 'a', function(e) {
//       e.preventDefault();
//       e.stopImmediatePropagation();
//       //  console.log('clicado accordeon');
//       $(this)
//         .parent()
//         .toggleClass('opened');
//       $(this)
//         .parent().parent()
//         .toggleClass('opened');
//       $(this)
//         .closest('.accordion-item')
//         .find('.accordion__item--reply')
//         .stop()
//         .slideToggle();
//       $(this)
//         .closest('.accordion-item')
//         .find('.accordion__item--question')
//         .find('.icon')
//         .toggleClass('clicked');
//       $(this)
//         .closest('.accordion-item')
//         .siblings()
//         .removeClass('opened')
//         .find('.accordion__item--question')
//         .removeClass('opened')
//         .find('.icon')
//         .removeClass('clicked');
//       $(this)
//         .closest('.accordion-item').siblings().find('.accordion__item--reply').stop().slideUp();
//       return false;
//     });
//
//   }
// }



