import {Injectable} from '@angular/core';
import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root'
})
export class ExcelService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getExcelUser(model) {
        const url = `${this.BASE_URL}/user/search/excel`;
        return this.http.post(url, model);
    }

    getExcelStartup(model) {
        const url = `${this.BASE_URL}/start-up/search/excel`;
        return this.http.post(url, model);
    }

    getExcelChallenge(model) {
        const url = `${this.BASE_URL}/challenge/search/excel`;
        return this.http.post(url, model);
    }

    getExcelCompany(model) {
        const url = `${this.BASE_URL}/company/search/excel`;
        return this.http.post(url, model);
    }

    getExcelComment(model) {
        const url = `${this.BASE_URL}/comment/search/excel`;
        return this.http.post(url, model);
    }





}
