import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TagService} from '../../service/tag.service';
import {CommonService} from '../../service/common.service';
import {TranslateService} from '@ngx-translate/core';
import {Tag, TagSearchResult, FilterTag, TagType} from '../../model/tag';
import {LanguageModel} from '../../model/common_model';


@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss']
})
export class TagsComponent extends BaseComponent implements OnInit {

    result: TagSearchResult = new TagSearchResult();
    page = 1;
    modelFilter: FilterTag = new FilterTag();
    languages: LanguageModel[] = [];
    types: TagType[] = [];
    typesEditSelect: TagType[] = [];
    modelList: Tag[] = [];
    modelFather: Tag = new Tag();
    modelIndex = 0;

    dropdownSettings = {
        singleSelection: false,
        idField: 'name',
        textField: 'description',
        selectAllText: 'Selecionar Todos',
        unSelectAllText: 'Desmarcar Todos',
        itemsShowLimit: 3,
        searchPlaceholderText: 'Pesquisar',
        noDataAvailablePlaceholderText: 'Sem informação disponível',
        allowSearchFilter: true
    };

    dropdownSettingsUnic = {
        singleSelection: true,
        idField: 'name',
        textField: 'description',
        selectAllText: 'Selecionar Todos',
        unSelectAllText: 'Desmarcar Todos',
        searchPlaceholderText: 'Pesquisar',
        noDataAvailablePlaceholderText: 'Sem informação disponível',
        allowSearchFilter: true
    };

    constructor(public router: Router,
                public tagService: TagService,
                public commonService: CommonService,
                public translate: TranslateService) {
        super(router, translate, 'modalChallengeEditTags');
    }

    ngOnInit(): void {
        this.actionList();
        this.getTypes();
        this.getLanguages();
    }

    actionList(): void {
        this.tagService.search(this.page, this.modelFilter).subscribe({
            next: data => {
                this.result = data as TagSearchResult;
            }, error: err => super.onError(err)
        });
    }

    actionClearFilter(): void {
        this.actionClear();
        this.modelFilter = new FilterTag();
        this.actionList();
    }

    getLanguages(): void {
        this.commonService.getLanguages().subscribe({
            next: data => {
                this.languages = data as LanguageModel[];
            }, error: err => super.onError(err)
        });
    }

    getTypes(): void {
        this.tagService.getTypes().subscribe({
            next: data => {
                this.types = data as TagType[];
            }, error: err => super.onError(err)
        });
    }

    actionCreate(): void {
        this.actionClear();
        for (const l of this.languages) {
            const c = new Tag();
            c.languageId = l.key;
            c.activate = false;
            this.modelList.push(c);
        }

        super.showModal();
    }

    actionDetail(id: string): void {
        this.actionClear();
        this.tagService.get(id).subscribe({
            next: data => {
                this.modelList = data as Tag[];
                this.typesEditSelect = [];
                for (const p of this.modelList[0].types) {
                    this.typesEditSelect.push(this.types.find(x => x.name === p));
                }
                this.modelFather = this.modelList[0];

                super.normalizeModels(this.modelList, this.languages);

                this.showModal();
            }, error: err => super.onError(err)
        });
    }

    actionSave(): void {
        let arrValidateFields = [];

        arrValidateFields = [
            {value: this.modelFather.color, text: 'Cor,<br>'},
            {value: this.typesEditSelect, text: 'Tipos,<br>'},
        ];

        for (const m of this.modelList.filter(x => x.activate)) {
            arrValidateFields.push({
                value: m.name,
                text: `Nome da Tag ${this.getLanguageName(m.languageId, this.languages)}, <br>`
            });
        }

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        for (const m of this.modelList) {
            m.color = this.modelFather.color;
            m.types = [];
            for (const p of this.typesEditSelect) {
                m.types.push(p.name);
            }
        }
        if (this.isNullOrUndefined(this.modelFather.id)) {
            if (this.modelList.filter(x => x.activate).length === 0) {
                this.showMessage('Atenção', `Preencha ao menos 1 idioma`, 'warning');
                return;
            }
            this.create();
        } else {
            this.update();
        }
    }

    create(): void {
        this.tagService.create(this.modelList.filter(x => x.activate)).subscribe({
            next: data => {
                super.showMessage('Sucesso', 'Tag criado com sucesso!', 'success');
                this.actionList();
                super.closeModal();
                this.actionClear();
            }, error: err => super.onError(err)
        });
    }

    update(): void {
        this.tagService.update(this.modelList.filter(x => x.activate), this.modelFather.id).subscribe({
            next: data => {
                this.modelList = data as Tag[];

                super.showMessage('Sucesso', 'Tag alterado com sucesso!', 'success');
                this.actionList();
                super.closeModal();
                this.actionClear();

            }, error: err => super.onError(err)
        });
    }

    actionRemove(id: any, lang: string): void {
        super.confirmMessage('Atenção', `Deseja realmente remover a Tag ${super.getLanguageName(lang, this.languages)}?`, () => {
            this.tagService.delete(id, lang).subscribe({
                next: data => {
                    this.actionList();
                    super.showMessage('Sucesso', 'Tag removida com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    pageChanged(event): void {
        this.page = event;
        this.actionList();
    }

    actionClear(): void {
        this.modelFather = new Tag();
        this.modelList = [];
        this.typesEditSelect = [];
    }

    actionSelectModel(index: number): void {
        this.modelIndex = index;
    }

    getTagDescription(tag: string): string {
        if (this.types.length === 0) {
            return '';
        }
        const l = this.types.find(a => a.name === tag);
        if (this.isNullOrUndefined(l)) {
            return '';
        }
        return l.description;
    }
}
