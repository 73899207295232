import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {Company, CompanySearchResult, FilterCompany} from '../../model/company';
import {CompanyService} from '../../service/company.service';
import {environment} from '../../../environments/environment';
import {CommonService} from '../../service/common.service';
import {LanguageModel} from '../../model/common_model';
import {TranslateService} from '@ngx-translate/core';
import {Tag} from '../../model/challenge';
import {ExcelService} from "../../service/excel.service";

@Component({
    selector: 'app-enterprise',
    templateUrl: './enterprise.component.html',
    styleUrls: ['./enterprise.component.scss']
})
export class EnterpriseComponent extends BaseComponent implements OnInit {

    result: CompanySearchResult = new CompanySearchResult();
    modelFilter: FilterCompany = new FilterCompany();
    modelList: Company[] = [];
    modelFather: Company = new Company();
    modelIndex = 0;
    page = 1;
    file: File = null;
    thumpPath: string | ArrayBuffer;
    languages: LanguageModel[] = [];
    tags: Tag[] = [];

    constructor(public router: Router,
                public companyService: CompanyService,
                public commonService: CommonService,
                public translate: TranslateService,
                public excelService: ExcelService) {
        super(router, translate, 'modalCompanyEdit');
    }

    ngOnInit(): void {
        this.actionList();
        this.getTags();
        this.getLanguages();
    }

    getLanguages(): void {
        this.commonService.getLanguages().subscribe({
            next: data => {
                this.languages = data as LanguageModel[];
            }, error: err => super.onError(err)
        });
    }

    getTags(): void {
        this.commonService.getTagsEnterprise().subscribe({
            next: data => {
                this.tags = data as Tag[];
            }, error: err => super.onError(err)
        });
    }

    actionList(): void {
        this.companyService.search(this.page, this.modelFilter).subscribe({
            next: data => {
                this.result = data as CompanySearchResult;
            }, error: err => super.onError(err)
        });
    }

    pageChanged(event): void {
        this.page = event;
        this.actionList();
    }

    getExcel(): void {
        this.excelService.getExcelCompany(this.modelFilter).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }

    actionDetail(id: string): void {
        this.actionClear();
        this.companyService.get(id).subscribe({
            next: data => {
                this.modelList = data as Company[];
                this.thumpPath = this.modelList[0].icon;
                this.modelFather = this.modelList[0];
                super.normalizeModels(this.modelList, this.languages);
                this.showModal();
            }, error: err => super.onError(err)
        });
    }

    actionSave(): void {
        let arrValidateFields = [];

        if (super.isNullOrUndefined(this.modelFather.id)) {
            arrValidateFields = [
                {value: this.file, text: 'Selecione um arquivo <br>'},
                {value: this.modelFather.highlight, text: 'Status <br>'},
                {value: this.modelFather.name, text: 'Nome <br>'},
                {value: this.modelFather.tagId, text: 'Tag <br>'},
            ];
        } else {
            arrValidateFields = [
                {value: this.modelFather.highlight, text: 'Status <br>'},
                {value: this.modelFather.name, text: 'Nome <br>'},
                {value: this.modelFather.tagId, text: 'Tag <br>'},
            ];
        }

        for (const m of this.modelList.filter(x => x.activate)) {
            arrValidateFields.push({
                value: m.description,
                text: `Descrição ${this.getLanguageName(m.languageId, this.languages)}, <br>`
            });
        }

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        for (const m of this.modelList) {
            m.highlight = this.modelFather.highlight;
            m.name = this.modelFather.name;
            m.tagId = this.modelFather.tagId;
        }

        if (this.isNullOrUndefined(this.modelFather.id)) {
            if (this.modelList.filter(x => x.activate).length === 0) {
                this.showMessage('Atenção', `Preencha ao menos 1 idioma`, 'warning');
                return;
            }
            this.create();
        } else {
            this.update();
        }
    }

    create(): void {
        this.companyService.create(this.modelList.filter(x => x.activate)).subscribe({
            next: data => {
                this.modelList = data as Company[];
                this.actionUploadImage('create');
            }, error: err => super.onError(err)
        });
    }

    update(): void {
        this.companyService.update(this.modelList.filter(x => x.activate), this.modelFather.id).subscribe({
            next: data => {
                this.modelList = data as Company[];
                if (!super.isNullOrUndefined(this.file)) {
                    this.actionUploadImage('update');
                } else {
                    super.showMessage('Sucesso', 'Empresa alterada com sucesso!', 'success');
                    this.actionList();
                    this.actionClear();
                    super.closeModal();
                }
            }, error: err => super.onError(err)
        });
    }

    actionRemove(id: any, lang: string): void {
        super.confirmMessage('Atenção', `Deseja realmente remover esta a empresa ${super.getLanguageName(lang, this.languages)}?`, () => {
            this.companyService.delete(id, lang).subscribe({
                next: data => {
                    this.actionList();
                    super.showMessage('Sucesso', 'Empresa removida com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    actionCreate(): void {
        this.actionClear();

        for (const l of this.languages) {
            const c = new Company();
            c.languageId = l.key;
            c.activate = false;
            this.modelList.push(c);
        }

        super.showModal();
    }

    handlerUpload(event): void {
        const files = event.target.files;
        if (files.length > 0) {
            if (files[0].size > 5000000) {
                super.showMessage('Atenção', 'O arquivo não deve ultrapassar o tamanho de 5MB', 'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (file) => {
            this.thumpPath = file.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);

        this.file = files[0];
    }

    actionUploadImage(type): void {
        if (!super.isNullOrUndefined(this.file)) {
            this.companyService.upload(this.file, this.modelList[0].id).subscribe({
                next: data => {
                    this.actionList();
                    super.closeModal();
                    this.actionClear();
                    if (type === 'create') {
                        super.showMessage('Sucesso', 'Empresa cadastrada com sucesso!', 'success');
                    } else {
                        super.showMessage('Sucesso', 'Empresa alterada com sucesso!', 'success');
                    }
                }, error: err => super.onError(err)
            });
        } else {
            this.actionList();
            super.closeModal();
            this.actionClear();
            if (type === 'create') {
                super.showMessage('Sucesso', 'Empresa cadastrada com sucesso!', 'success');
            } else {
                super.showMessage('Sucesso', 'Empresa alterada com sucesso!', 'success');
            }
        }
    }

    actionClearFilter(): void {
        this.modelFilter = new FilterCompany();
        this.actionList();
    }

    actionClear(): void {
        this.modelFather = new Company();
        this.modelList = [];
        this.modelIndex = 0;
        this.file = null;
        this.thumpPath = '';
    }

    actionSelectModel(index: number): void {
        this.modelIndex = index;
    }
}
