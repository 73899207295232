<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<section class="container">

    <div class="d-flex align-items-center ml-5 mt-4">
        <svg-icon class="svg" src="assets/svg/challenges.svg"></svg-icon>
        <h1 class="title ml-4">Gestão de Desafios</h1>
    </div>

    <div class="row">
        <div class="col-sm-12 text-right">
            <a (click)="actionCreate()" href="javascript:void('');"
               class="btn btn-ico btn-sm  mt-3 mt-lg-0 btn-success mx-2 mb-3 mb-xs-0">
                <svg-icon class="svg-white" src="assets/svg/add.svg"></svg-icon>
                Novo Desafio
            </a>
        </div>
    </div>

    <div class="mt-3">

        <div class="d-sm-flex align-items-center justify-content-between mt-4 mx-4">
            <strong class="subTitle"></strong>
        </div>

        <div class="card mt-3 bg-white mx-2">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="challengeTitle" name="challengeTitle" [(ngModel)]="modelFilter.title"
                                   class="form-control" type="text"
                                   maxlength="200"
                                   autocomplete="off" required/>
                            <label for="challengeTitle">Titulo</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="challengeDesc" name="challengeDesc" [(ngModel)]="modelFilter.description"
                                   class="form-control"
                                   type="text" maxlength="200"
                                   autocomplete="off" required/>
                            <label for="challengeDesc">Descrição</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <select [(ngModel)]="modelFilter.language" id="language" class="form-control"
                                    name="language">
                                <option [ngValue]="''">Todos</option>
                                <option *ngFor="let l of languages" value="{{l.key}}">{{l.value}}</option>
                            </select>
                            <label for="language" class="mb-0 mr-3">Idioma</label>
                        </div>
                    </div>
                    <!--<div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="challengeTime" class="form-control js-daterangepicker" type="text"
                                   maxlength="200" autocomplete="off"
                                   required/>
                            <label for="challengeTime">Período</label>
                        </div>
                    </div>-->
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <select id="tag" name="tag" [(ngModel)]="modelFilter.tagId"
                                    class="form-control">
                                <option [ngValue]="undefined">Todas</option>
                                <option *ngFor="let t of listTags" value="{{t.id}}">{{t.name}}</option>
                            </select>
                            <label for="tag" class="mb-0 mr-3">Tag</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-info mx-2 mb-3 mb-xs-0"
                           (click)="getExcel()">
                            <svg-icon class="svg-white" src="assets/svg/excel.svg"></svg-icon>
                            Exportar Excel
                        </a>
                        <a href="javascript:void('');" (click)="actionClearFilter()"
                           class="btn btn-ico btn-sm btn-danger mx-2 mb-3 mb-xs-0">
                            <svg-icon class="svg-white" src="assets/svg/delete.svg"></svg-icon>
                            Limpar Filtro
                        </a>
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0"
                           (click)="page = 1;actionList()">
                            <svg-icon class="svg-white" src="assets/svg/search.svg"></svg-icon>
                            Buscar
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-3 mx-2 bg-white p-3">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th class="align-middle text-center" scope="col">Informações</th>
                        <th class="align-middle text-center" scope="col">Tag</th>
                        <th class="align-middle text-center" scope="col">Período</th>
                        <th colspan="2"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let r of result?.results |
                                paginate: {
                                 itemsPerPage: result.pageSize,
                                 currentPage: result.currentPage,
                                 totalItems: result.totalCount}">
                        <td class="text-center">
                            <p *ngFor="let l of r.languages">
                                <b>{{getLanguageName(l.languageId, languages)}}:</b> {{l.title}}
                            </p>
                        </td>
                        <td class="text-center">{{r.tag.name}}</td>
                        <td class="text-center">{{r.start | date: 'dd/MM/yy'}} à {{r.end | date: 'dd/MM/yy'}}</td>
                        <td class="text-center">
                            <a (click)="actionDetail(r.id)"
                               class="btn btn-ico btn-ico-no-marg btn-sm btn-warning mx-2 mb-3 mb-xs-0">
                                <svg-icon src="assets/svg/edit.svg" [svgStyle]="{'fill':'white'}"></svg-icon>
                            </a>
                        </td>
                        <td class="text-center">
                            <div class="dropdown show">
                                <a href="javascript:void('');"
                                   class="btn btn-ico btn-ico-no-marg btn-sm btn-danger mx-2 mb-3 mb-xs-0 dropdown-toggle"
                                   role="button" id="dropdownMenuLink" data-toggle="dropdown"
                                   aria-haspopup="true" aria-expanded="false">
                                    <svg-icon class="svg-white" src="assets/svg/delete.svg"></svg-icon>
                                </a>

                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <a href="javascript:void('');"
                                       class="dropdown-item" *ngFor="let it of r.languages"
                                       (click)="actionRemove(r.id, it.languageId)">
                                        {{getLanguageName(it.languageId, this.languages)}}</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="isNullOrUndefined(result?.results)">
                        <td colspan="6">
                            <p class="pt-5 pb-5 text-center">
                                Nenhum registro encontrado.
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-center">
                <pagination-controls
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
        </div>

    </div>
</section>

<div class="modal fade" id="modalChallengeEdit" tabindex="-1" role="dialog" aria-labelledby="moreLabel"
     aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <strong *ngIf="!isNullOrUndefined(modelFather?.id)" class="text-primary">Editar Desafio</strong>
                    <strong *ngIf="isNullOrUndefined(modelFather?.id)" class="text-primary">Cadastrar Desafio</strong>
                </h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon class="svg-icon-primary" src="assets/svg/fechar.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body  px-0 pb-0">
                <div class="card mt-3 bg-white mx-2">
                    <div class="card-body">
                        <div class="row justify-content-center  d-flex flex-column align-items-center mt-2 mb-2">
                            <img class="challengePhoto" *ngIf="!isNullOrUndefined(thumpPath)" style="max-height: 210px;"
                                 src="{{thumpPath}}"
                                 alt="Responsive image">
                            <svg-icon *ngIf="!isNullOrUndefined(thumpPath)" class="editIcon editPhoto"
                                      src="assets/svg/edit.svg"></svg-icon>
                            <input type="file" class="BannerFile" onclick="this.value = null"
                                   (change)="handlerUpload($event)">
                            <svg-icon *ngIf="isNullOrUndefined(thumpPath)" class="svg"
                                      src="assets/svg/upload.svg"></svg-icon>
                            <p *ngIf="isNullOrUndefined(thumpPath)" class="text-primary">(Uploads de imagens de até
                                5mb)</p>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <div class="input-material">
                                    <select [(ngModel)]="modelFather.tagId" id="tagEdit" class="form-control"
                                            name="tagEdit">
                                        <option *ngFor="let l of listTags" value="{{l.id}}">{{l.name}}</option>
                                    </select>
                                    <label for="tagEdit" class="mb-0 mr-3">Tag</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="input-material">
                                        <input id="challengeTimeEdit" class="form-control js-daterangepicker-edit"
                                               type="text"
                                               maxlength="200" autocomplete="off"
                                               required/>
                                        <label for="challengeTimeEdit">Período</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="input-material">
                                    <input id="link"
                                           name="link"
                                           [(ngModel)]="modelFather.link"
                                           class="form-control"
                                           type="text" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="challengeTitleEdit">Link</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <ul class="col-12 d-flex justify-content-center nav nav-tabs mb-3">
                                <li class="nav-item" *ngFor="let m of modelList; let i = index">
                                    <a class="nav-link pe-auto cursor-pointer" [ngClass]="{'active': modelIndex === i}"
                                       (click)="actionSelectModel(i)">{{getLanguageName(m.languageId, languages)}}</a>
                                </li>
                            </ul>
                            <div *ngIf="!isNullOrUndefined(modelList)" class="col-12">
                                <div class="row mt-2">
                                    <div *ngIf="isNullOrUndefined(modelList[modelIndex].id)"
                                         class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="d-flex" style="gap: 15px; align-items: center;">
                                            <label class="mb-0 duk-check">
                                                <input [(ngModel)]="modelList[modelIndex].activate"
                                                       [ngModelOptions]="{standalone: true}"
                                                       id="activeCreate"
                                                       name="activeCreate"
                                                       type="checkbox"/>
                                                <span class="checkmark">
                                                    <svg-icon src="assets/svg/check.svg"></svg-icon>
                                                </span>
                                                <span>Ativar</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-6">
                                        <div class="input-material">
                                            <input id="challengeTitleEdit"
                                                   name="challengeTitleEdit"
                                                   [(ngModel)]="modelList[modelIndex].title"
                                                   class="form-control"
                                                   [disabled]="!modelList[modelIndex].activate"
                                                   type="text" maxlength="200"
                                                   autocomplete="off" required/>
                                            <label for="challengeTitleEdit">Título</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-6">
                                        <div class="input-material">
                                            <input id="challengeDescEdit" name="challengeDescEdit"
                                                   [(ngModel)]="modelList[modelIndex].description" class="form-control"
                                                   type="text" maxlength="200"
                                                   [disabled]="!modelList[modelIndex].activate"
                                                   autocomplete="off" required/>
                                            <label for="challengeDescEdit">Descrição</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="input-material d-flex">
                                            <input [(ngModel)]="modelList[modelIndex].video" name="video" id="video"
                                                   class="form-control"
                                                   type="text"
                                                   [disabled]="!modelList[modelIndex].activate"
                                                   maxlength="200"
                                                   autocomplete="off"/>
                                            <label for="video">Vídeo</label>
                                        </div>
                                        <span id="product" class="badge-rounded"> ? </span>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="input-material">
                                    <textarea [(ngModel)]="modelList[modelIndex].problem" name="textProblem"
                                              id="textProblem"
                                              class="form-control"
                                              [disabled]="!modelList[modelIndex].activate"
                                              type="text" rows="3"
                                              autocomplete="off" required></textarea>
                                            <label for="textProblem">Problema que queremos resolver</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="input-material">
                                    <textarea [(ngModel)]="modelList[modelIndex].objective" name="textObjective"
                                              id="textObjective"
                                              class="form-control"
                                              [disabled]="!modelList[modelIndex].activate"
                                              type="text"rows="3"
                                              autocomplete="off" required></textarea>
                                            <label for="textObjective">O que buscamos</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-sm-12 text-right">
                                <a href="javascript:void('');" (click)="actionSave()"
                                   class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0">
                                    <svg-icon class="svg-white" src="assets/svg/disquet.svg"></svg-icon>
                                    Salvar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
