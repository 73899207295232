export class User {
  id: string;
  name: string;
  email: string;
  profile: string;
  password: string;
  confirmPassword: string;
  photo: string;
  createdAt: Date;
  updatedAt: Date;

  constructor() {
  }
}

export class UserSearchResult {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPage: number;
  previousPage: boolean;
  nextPage: boolean;
  results: User[];
  constructor() {
    this.results = [];
  }
}

export class FilterUser {
  name: string;
  email: string;
  profile: string;
}
