import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CommonService extends ServiceBase {
    constructor(http: HttpClient) {
        super(http);
    }

    getLanguages() {
        const url = `${this.BASE_URL}/common/list-languages`;
        return this.http.get(url);
    }

    getStages() {
        const url = `${this.BASE_URL}/common/list-stages`;
        return this.http.get(url);
    }

    getTagsChallenge() {
        const url = `${this.BASE_URL}/common/list-tags/challenge`;
        return this.http.get(url);
    }

    getTagsEnterprise() {
        const url = `${this.BASE_URL}/common/list-tags/company`;
        return this.http.get(url);
    }
}
