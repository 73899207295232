<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<section class="container">

    <div class="d-flex align-items-center ml-5 mt-4">
        <svg-icon class="svg" src="assets/svg/startups.svg"></svg-icon>
        <h1 class="title ml-4">Gestão de Startups</h1>
    </div>

    <div class="mt-3">

        <div class="d-sm-flex align-items-center justify-content-between mt-4 mx-4">
            <strong class="subTitle"></strong>
        </div>

        <div class="card mt-3 bg-white mx-2">
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-md-12 col-lg-4 col-xxl-3">
                        <div class="input-material">
                            <input id="challengeTime" class="form-control js-daterangepicker" type="text"
                                   maxlength="200" autocomplete="off"
                                   required/>
                            <label for="challengeTime">Período</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 col-xxl-3">
                        <div class="input-material">
                            <input id="startupName" name="startupName" [(ngModel)]="modelFilter.name"
                                   class="form-control" type="text"
                                   maxlength="200" autocomplete="off"
                                   required/>
                            <label for="startupName">Nome da Startup</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 col-xxl-3">
                        <div class="input-material">
                            <input id="startupEmail" name="startupEmail" [(ngModel)]="modelFilter.email"
                                   class="form-control" type="text"
                                   maxlength="200" autocomplete="off"
                                   required/>
                            <label for="startupEmail">Email</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 col-xxl-3">
                        <div class="input-material">
                            <input id="startupCity" name="startupCity" [(ngModel)]="modelFilter.city"
                                   class="form-control" type="text"
                                   maxlength="200" autocomplete="off"
                                   required/>
                            <label for="startupCity">Cidade</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 col-xxl-3">
                        <div class="input-material">
                            <input id="startupCountry" name="startupCountry" [(ngModel)]="modelFilter.country"
                                   class="form-control" type="text"
                                   maxlength="200" autocomplete="off"
                                   required/>
                            <label for="startupCountry">País</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 col-xxl-2">
                        <div class="input-material">
                            <input id="startupFoundation" name="startupFoundation"
                                   [(ngModel)]="modelFilter.foundationYear" class="form-control"
                                   type="number" maxlength="200"
                                   autocomplete="off" required/>
                            <label for="startupFoundation">Ano de Fundação</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 col-xxl-3">
                        <div class="input-material">
                            <ng-multiselect-dropdown
                                    [placeholder]="'Selecione Estágios'"
                                    [data]="listStage"
                                    [(ngModel)]="stagesFilterSelected"
                                    [settings]="dropdownSettings">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 col-xxl-2">
                        <div class="input-material">
                            <input id="startupContributors" name="startupContributors"
                                   [(ngModel)]="modelFilter.contributors" class="form-control"
                                   type="number" maxlength="200"
                                   autocomplete="off" required/>
                            <label for="startupContributors">Colaboradores</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 col-xxl-2">
                        <div class="input-material">
                            <select [(ngModel)]="modelFilter.language" id="language" class="form-control"
                                    name="language">
                                <option [ngValue]="undefined">Todos</option>
                                <option *ngFor="let l of languages" value="{{l.key}}">{{l.value}}</option>
                            </select>
                            <label for="language" class="mb-0 mr-3">Idioma</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-info mx-2 mb-3 mb-xs-0"
                           (click)="getExcel()">
                            <svg-icon class="svg-white" src="assets/svg/excel.svg"></svg-icon>
                            Exportar Excel
                        </a>
                        <a href="javascript:void('');" (click)="actionClearFilter()"
                           class="btn btn-ico btn-sm btn-danger mx-2 mb-3 mb-xs-0">
                            <svg-icon class="svg-white" src="assets/svg/delete.svg"></svg-icon>
                            Limpar Filtro
                        </a>
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0"
                           (click)="page = 1;actionList()">
                            <svg-icon class="svg-white" src="assets/svg/search.svg"></svg-icon>
                            Buscar
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="card mt-3 mx-2 bg-white p-3 mt-4">
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th class="align-middle text-center" scope="col">Nome</th>
                    <th class="align-middle text-center" scope="col">País</th>
                    <th class="align-middle text-center" scope="col">Cidade</th>
                    <th class="align-middle text-center" scope="col">Ano de Fundação</th>
                    <th class="align-middle text-center" scope="col">Estagio</th>
                    <th class="align-middle text-center" scope="col">Colaboradores</th>
                    <th class="align-middle text-center" scope="col">Idioma</th>
                    <th class="align-middle text-center" scope="col">Período</th>
                    <th colspan="2" class="w-10"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let r of result?.results |
                                paginate: {
                                 itemsPerPage: result.pageSize,
                                 currentPage: result.currentPage,
                                 totalItems: result.totalCount}">
                    <td class="text-center">{{r.name}}</td>
                    <td class="text-center">{{r.country}}</td>
                    <td class="text-center">{{r.city}}</td>
                    <td class="text-center">{{r.yearFoundation}}</td>
                    <td class="text-center">{{r.stage}}</td>
                    <td class="text-center">{{r.contributors}}</td>
                    <td class="text-center">{{getLanguageName(r.language)}}</td>
                    <td class="text-center">{{r.createdAt | date: 'dd/MM/yyyy'}}</td>
                    <td class="text-center">
                        <a href="javascript:void('');" class="btn btn-ico btn-ico-no-marg btn-sm btn-warning mx-2 mb-3 mb-xs-0" (click)="actionDetail(r.id)">
                            <svg-icon  class="svg-white" src="assets/svg/edit.svg"></svg-icon>
                        </a>
                    </td>
                    <td class="text-center">
                        <a href="javascript:void('');" class="btn btn-ico btn-ico-no-marg btn-sm btn-danger mx-2 mb-3 mb-xs-0" (click)="actionRemove(r.id)">
                            <svg-icon class="svg-white" src="assets/svg/delete.svg"></svg-icon>
                        </a>
                    </td>
                </tr>
                <tr *ngIf="isNullOrUndefined(result?.results)">
                    <td colspan="8">
                        <p class="pt-5 pb-5 text-center">
                            Nenhum registro encontrado.
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-center">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</section>

<div class="modal fade" id="modalStartupEdit" tabindex="-1" role="dialog" aria-labelledby="moreLabel"
     aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <strong class="text-primary">Editar Startups</strong>
                </h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon class="svg-icon-primary" src="assets/svg/fechar.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body  px-0 pb-0">
                <div class="card mt-3 bg-white mx-2">
                    <div class="card-body">
                        <!--<div class="row justify-content-center  d-flex flex-column align-items-center mt-2 mb-2">
                            <img *ngIf="!isNullOrUndefined(thumpPath)" style="max-height: 210px;" src="{{thumpPath}}"
                                 alt="Responsive image">
                            <input type="file" class="BannerFile" onclick="this.value = null"
                                   (change)="handlerUpload($event)">
                            <svg-icon class="svg" src="assets/svg/upload.svg"></svg-icon>
                            <p class="text-primary">(Uploads de imagens de até 5mb)</p>
                        </div>-->
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="input-material">
                                    <input id="statusEdit" name="statusEdit" [(ngModel)]="model.status"
                                           class="form-control"
                                           type="text" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="statusEdit">Status</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-5">
                                <div class="input-material">
                                    <input id="startupNameEdit" name="startupNameEdit" [(ngModel)]="model.name"
                                           class="form-control"
                                           type="text" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="startupNameEdit">Nome da Startup</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="input-material">
                                    <input id="startupPhoneEdit"
                                           [mask]="'(00) 0000-0000||(00) 00000-0000'"
                                           [(ngModel)]="model.cellphone"
                                           [dropSpecialCharacters]="false"
                                           (blur)="onClearModel($event, 'cellphone')"
                                           [clearIfNotMatch]="true"
                                           name="startupPhoneEdit"
                                           class="form-control" type="text" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="startupPhoneEdit">Telefone</label>
                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="input-material">
                                    <input id="startupHubEdit" [(ngModel)]="model.hub"
                                           name="startupHubEdit"
                                           class="form-control" type="text" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="startupHubEdit">Hub</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-8">
                                <div class="input-material">
                                    <input id="startupHubDescEdit" [(ngModel)]="model.hubDescription"
                                           name="startupHubDescEdit"
                                           class="form-control" type="text" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="startupHubDescEdit">Hub Descrição</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="input-material">
                                    <input id="startupSiteEdit" [(ngModel)]="model.site"
                                           name="startupSiteEdit"
                                           class="form-control" type="text" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="startupSiteEdit">Site</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="input-material">
                                    <input id="startupEmailEdit" [(ngModel)]="model.email"
                                           name="startupEmailEdit"
                                           class="form-control" type="text" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="startupEmailEdit">E-mail</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4">
                                <div class="input-material">
                                    <input id="startupLinkedinEdit" [(ngModel)]="model.linkedin"
                                           name="startupLinkedinEdit"
                                           class="form-control" type="text" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="startupLinkedinEdit">Linkedin</label>
                                </div>
                            </div>


                            <div class="col-sm-12 col-md-7 col-lg-3">
                                <div class="input-material">
                                    <input id="startupCountryEdit" [(ngModel)]="model.country" name="startupCountryEdit"
                                           class="form-control" type="text" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="startupCountryEdit">País</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-5 col-lg-2">
                                <div class="input-material">
                                    <input id="startupUfEdit" [(ngModel)]="model.uf" name="startupUfEdit"
                                           class="form-control" type="text" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="startupUfEdit">Estado</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-4">
                                <div class="input-material">
                                    <input id="startupCityEdit" [(ngModel)]="model.city" name="startupCityEdit"
                                           class="form-control" type="text" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="startupCityEdit">Cidade</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-3">
                                <div class="input-material">
                                    <input id="startupBairroEdit" [(ngModel)]="model.neighborhood"
                                           name="startupBairroEdit"
                                           class="form-control" type="text" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="startupBairroEdit">Bairro</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-3">
                                <div class="input-material">
                                    <input id="startupCepEdit"
                                           [mask]="'00000-000'"
                                           [dropSpecialCharacters]="false"
                                           [clearIfNotMatch]="true"
                                           [(ngModel)]="model.cep"
                                           (blur)="onClearModel($event, 'cep')"
                                           name="startupCepEdit"
                                           class="form-control" type="text" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="startupCepEdit">Cep</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <div class="input-material">
                                    <input id="startupAddressEdit" [(ngModel)]="model.address" name="startupAddressEdit"
                                           class="form-control" type="text" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="startupAddressEdit">Endereço</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-3">
                                <div class="input-material">
                                    <input id="startupNumberEdit" [(ngModel)]="model.number" name="startupNumberEdit"
                                           class="form-control" type="text" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="startupNumberEdit">Número</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-3">
                                <div class="input-material">
                                    <input id="startupFoundationEdit" name="startupFoundationEdit"
                                           [(ngModel)]="model.yearFoundation"
                                           class="form-control" type="number" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="startupFoundationEdit">Ano de Fundação</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-3">
                                <div class="input-material">
                                    <ng-multiselect-dropdown
                                            [placeholder]="'Selecione o Estágio'"
                                            [data]="listStage"
                                            [(ngModel)]="model.stage"
                                            [settings]="dropdownEditSettings">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-3">
                                <div class="input-material">
                                    <input id="startupContributorsEdit" [(ngModel)]="model.contributors"
                                           name="startupContributorsEdit"
                                           class="form-control" type="number" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="startupContributorsEdit">Colaboradores</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-3">
                                <div class="input-material">
                                    <select [(ngModel)]="model.language" id="languageEdit" class="form-control"
                                            name="languageEdit">
                                        <option *ngFor="let l of languages" [ngValue]="l.key">{{l.value}}</option>
                                    </select>
                                    <label for="languageEdit" class="mb-0 mr-3">Idioma</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-sm-12 text-right">
                                <a href="javascript:void('');" (click)="actionSave()"
                                   class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0">
                                    <svg-icon class="svg-white" src="assets/svg/disquet.svg"></svg-icon>
                                    Salvar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

