<section class="container">

    <div class="d-flex align-items-center ml-5 mt-4">
        <svg-icon class="svg" src="assets/svg/tag.svg"></svg-icon>
        <h1 class="title ml-4">Gestão de Tags</h1>
    </div>

    <div class="row">
        <div class="col-sm-12 text-right">
            <a (click)="actionCreate()" href="javascript:void('');"
               class="btn btn-ico btn-sm  mt-3 mt-lg-0 btn-success mx-2 mb-3 mb-xs-0">
                <svg-icon class="svg-white" src="assets/svg/add.svg"></svg-icon>
                Nova Tag
            </a>
        </div>
    </div>

    <div class="mt-3">

        <div class="d-sm-flex align-items-center justify-content-between mt-4 mx-4">
            <strong class="subTitle"></strong>
        </div>

        <div class="card mt-3 bg-white mx-2">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="challengeTitle" name="challengeTitle" [(ngModel)]="modelFilter.name"
                                   class="form-control" type="text"
                                   maxlength="200"
                                   autocomplete="off" required/>
                            <label for="challengeTitle">Titulo</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-3">
                        <div class="input-material">
                            <ng-multiselect-dropdown
                                    [placeholder]="'Selecione um tipo'"
                                    [data]="types"
                                    [(ngModel)]="modelFilter.types"
                                    [settings]="dropdownSettingsUnic">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <a href="javascript:void('');" (click)="actionClearFilter()"
                           class="btn btn-ico btn-sm btn-danger mx-2 mb-3 mb-xs-0">
                            <svg-icon class="svg-white" src="assets/svg/delete.svg"></svg-icon>
                            Limpar Filtro
                        </a>
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0"
                           (click)="page = 1;actionList()">
                            <svg-icon class="svg-white" src="assets/svg/search.svg"></svg-icon>
                            Buscar
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-3 mx-2 bg-white p-3">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th class="align-middle text-center" scope="col">Tipos</th>
                        <th class="align-middle text-center" scope="col">Cor</th>
                        <th class="align-middle text-center" scope="col">Nome</th>
                        <th colspan="2"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let r of result?.results |
                                paginate: {
                                 itemsPerPage: result.pageSize,
                                 currentPage: result.currentPage,
                                 totalItems: result.totalCount}">
                        <td class="text-center">
                            <span *ngFor="let t of r.types,let i = index">
                                <span *ngIf="i != 0">, </span>{{getTagDescription(t)}}</span>
                        </td>
                        <td class="text-center">{{r.color}}</td>
                        <td class="text-center">
                            <p *ngFor="let l of r.languages">
                                <b>{{getLanguageName(l.languageId, languages)}}:</b> {{l.name}}
                            </p>
                        </td>
                        <td class="text-center">
                            <a (click)="actionDetail(r.id)"
                               class="btn btn-ico btn-ico-no-marg btn-sm btn-warning mx-2 mb-3 mb-xs-0">
                                <svg-icon src="assets/svg/edit.svg" [svgStyle]="{'fill':'white'}"></svg-icon>
                            </a>
                        </td>
                        <td class="text-center">
                            <div class="dropdown show">
                                <a href="javascript:void('');"
                                   class="btn btn-ico btn-ico-no-marg btn-sm btn-danger mx-2 mb-3 mb-xs-0 dropdown-toggle"
                                   role="button" id="dropdownMenuLink" data-toggle="dropdown"
                                   aria-haspopup="true" aria-expanded="false">
                                    <svg-icon class="svg-white" src="assets/svg/delete.svg"></svg-icon>
                                </a>

                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <a href="javascript:void('');"
                                       class="dropdown-item" *ngFor="let it of r.languages"
                                       (click)="actionRemove(r.id, it.languageId)">
                                        {{getLanguageName(it.languageId, this.languages)}}</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="isNullOrUndefined(result?.results)">
                        <td colspan="6">
                            <p class="pt-5 pb-5 text-center">
                                Nenhum registro encontrado.
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-center">
                <pagination-controls
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
        </div>

    </div>
</section>

<div class="modal fade" id="modalChallengeEditTags" tabindex="-1" role="dialog" aria-labelledby="moreLabel"
     aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <strong *ngIf="!isNullOrUndefined(modelFather?.id)" class="text-primary">Editar Tag</strong>
                    <strong *ngIf="isNullOrUndefined(modelFather?.id)" class="text-primary">Cadastrar Tag</strong>
                </h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon class="svg-icon-primary" src="assets/svg/fechar.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body  px-0 pb-0">
                <div class="card mt-3 bg-white mx-2">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <div class="input-material">
                                    <input [(colorPicker)]="modelFather.color" [style.background]="modelFather.color"
                                           [(ngModel)]="modelFather.color" name="tagColor" id="tagColor"
                                           class="form-control"
                                           type="text"
                                           maxlength="200"
                                           autocomplete="off"/>
                                    <label for="tagColor" class="mb-0 mr-3">Cor</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6">
                                <div class="input-material">
                                    <ng-multiselect-dropdown
                                            [placeholder]="'Selecione os tipos'"
                                            [data]="types"
                                            [(ngModel)]="typesEditSelect"
                                            [settings]="dropdownSettings">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                            <ul class="col-12 d-flex justify-content-center nav nav-tabs mb-3">
                                <li class="nav-item" *ngFor="let m of modelList; let i = index">
                                    <a class="nav-link pe-auto cursor-pointer" [ngClass]="{'active': modelIndex === i}"
                                       (click)="actionSelectModel(i)">{{getLanguageName(m.languageId, languages)}}</a>
                                </li>
                            </ul>
                            <div *ngIf="!isNullOrUndefined(modelList)" class="col-12">
                                <div class="row">
                                    <div *ngIf="isNullOrUndefined(modelList[modelIndex].id)"
                                         class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="d-flex" style="gap: 15px; align-items: center;">
                                            <label class="mb-0 duk-check">
                                                <input [(ngModel)]="modelList[modelIndex].activate"
                                                       [ngModelOptions]="{standalone: true}"
                                                       id="activeCreate"
                                                       name="activeCreate"
                                                       type="checkbox"/>
                                                <span class="checkmark">
                                                    <svg-icon src="assets/svg/check.svg"></svg-icon>
                                                </span>
                                                <span>Ativar</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="input-material">
                                            <input [(ngModel)]="modelList[modelIndex].name" id="tagName" name="tagName"
                                                   class="form-control"
                                                   [disabled]="!modelList[modelIndex].activate"
                                                   type="text" maxlength="255"
                                                   autocomplete="off" required/>
                                            <label for="tagName">Nome da Tag</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-sm-12 text-right">
                                <a href="javascript:void('');" (click)="actionSave()"
                                   class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0">
                                    <svg-icon class="svg-white" src="assets/svg/disquet.svg"></svg-icon>
                                    Salvar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


