import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {CommentService} from '../../service/comment.service';
import {CommentModel, CommentSearchResult, FilterComment} from '../../model/comment';
import {LanguageModel} from '../../model/common_model';
import {CommonService} from '../../service/common.service';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.scss']
})
export class CommentsComponent extends BaseComponent implements OnInit, AfterViewInit {

    result: CommentSearchResult = new CommentSearchResult();
    modelFilter: FilterComment = new FilterComment();
    modelList: CommentModel[] = [];
    modelFather: CommentModel = new CommentModel();
    modelIndex = 0;
    page = 1;
    file: File = null;
    thumpPath: string | ArrayBuffer;
    languages: LanguageModel[] = [];

    constructor(public router: Router,
                public commentService: CommentService,
                public commonService: CommonService,
                public translate: TranslateService) {
        super(router, translate, 'modalCommentEdit');
    }

    ngOnInit(): void {
        this.actionList();
        this.getLanguages();
    }

    ngAfterViewInit(): void {
        this.popoverStruct('product',
            'Link do video',
            'Este campo devera conter o link do video do youtube, como no exemplo a seguir.');
    }

    popoverStruct(id: string, title: string, content: string): any {
        return $('#' + id).popover({
            title: '',
            content: 'That',
            trigger: 'hover',
            placement: 'right',
            template: '<div class="popover alert-error">' +
                '<div class="arrow"></div>' +
                '<div class="popover-inner">' +
                '<div class="popover-content">' +
                '<div class="border-right-custom p-2">' +
                '<p class="mb-0 text-uppercase font-weight-bold size-12">' + title + '</p>' +
                '<p class="info size-10"> ' + content + ' </p>' +
                '<img src="assets/images/video.jpg"> ' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</div>',
        });
    }

    getLanguages(): void {
        this.commonService.getLanguages().subscribe({
            next: data => {
                this.languages = data as LanguageModel[];
            }, error: err => super.onError(err)
        });
    }

    actionList(): void {
        this.commentService.search(this.page, this.modelFilter).subscribe({
            next: data => {
                this.result = data as CommentSearchResult;
            }, error: err => super.onError(err)
        });
    }

    actionClearFilter(): void {
        this.modelFilter = new FilterComment();
        this.actionList();
    }

    pageChanged(event): void {
        this.page = event;
        this.actionList();
    }

    /*getExcel(): void {
      const token = localStorage.getItem('token') as string;
      let url = `${environment.base_url}/comment/search/excel?token=${token}`;

      if (!super.isNullOrUndefined(this.modelFilter.name)) {
        url += ('&name=' + this.modelFilter.name);
      }

      if (!super.isNullOrUndefined(this.modelFilter.email)) {
        url += ('&email=' + this.modelFilter.email);
      }

      if (!super.isNullOrUndefined(this.modelFilter.profile)) {
        url += ('&profile=' + this.modelFilter.profile);
      }
      window.open(encodeURI(url));
    }*/

    actionDetail(id: string): void {
        this.actionClear();
        this.commentService.get(id).subscribe({
            next: data => {
                this.modelList = data as CommentModel[];
                this.thumpPath = this.modelList[0].photo;
                this.modelFather = this.modelList[0];
                super.normalizeModels(this.modelList, this.languages);
                this.showModal();
            }, error: err => super.onError(err)
        });
    }

    actionSave(): void {
        let arrValidateFields = [];

        if (super.isNullOrUndefined(this.modelFather.id)) {
            arrValidateFields = [
                {value: this.file, text: 'Selecione um arquivo <br>'},
                {value: this.modelFather.highlight, text: 'Destaque <br>'},
                {value: this.modelFather.name, text: 'Nome <br>'},
                {value: this.modelFather.companyName, text: 'Empresa <br>'},
            ];
        } else {
            arrValidateFields = [
                {value: this.modelFather.highlight, text: 'Destaque <br>'},
                {value: this.modelFather.name, text: 'Nome <br>'},
                {value: this.modelFather.companyName, text: 'Empresa <br>'},
            ];
        }

        for (const m of this.modelList.filter(x => x.activate)) {
            arrValidateFields.push({
                value: m.jobRole,
                text: `Profissão ${this.getLanguageName(m.languageId, this.languages)}, <br>`
            });
            arrValidateFields.push({
                value: m.text,
                text: `Comentário ${this.getLanguageName(m.languageId, this.languages)}, <br>`
            });
        }

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        for (const m of this.modelList) {
            m.highlight = this.modelFather.highlight;
            m.name = this.modelFather.name;
            m.companyName = this.modelFather.companyName;
        }

        if (this.isNullOrUndefined(this.modelFather.id)) {
            if (this.modelList.filter(x => x.activate).length === 0) {
                this.showMessage('Atenção', `Preencha ao menos 1 idioma`, 'warning');
                return;
            }
            this.create();
        } else {
            this.update();
        }
    }

    create(): void {
        this.commentService.create(this.modelList.filter(x => x.activate)).subscribe({
            next: data => {
                this.modelList = data as CommentModel[];
                this.actionUploadImage('create');
            }, error: err => super.onError(err)
        });
    }

    update(): void {
        this.commentService.update(this.modelList.filter(x => x.activate), this.modelFather.id).subscribe({
            next: data => {
                this.modelList = data as CommentModel[];
                if (!super.isNullOrUndefined(this.file)) {
                    this.actionUploadImage('update');
                } else {
                    super.showMessage('Sucesso', 'Comentário alterado com sucesso!', 'success');
                    this.actionList();
                    this.actionClear();
                    super.closeModal();
                }
            }, error: err => super.onError(err)
        });
    }

    actionRemove(id: any, lang: string): void {
        super.confirmMessage('Atenção', `Deseja realmente remover o comentário ${super.getLanguageName(lang, this.languages)}?`, () => {
            this.commentService.delete(id, lang).subscribe({
                next: data => {
                    this.actionList();
                    super.showMessage('Sucesso', 'Comentário removido com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    actionCreate(): void {
        this.actionClear();

        for (const l of this.languages) {
            const c = new CommentModel();
            c.languageId = l.key;
            c.activate = false;
            this.modelList.push(c);
        }

        super.showModal();
    }

    handlerUpload(event): void {
        const files = event.target.files;
        if (files.length > 0) {
            if (files[0].size > 5000000) {
                super.showMessage('Atenção', 'O arquivo não deve ultrapassar o tamanho de 5MB', 'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (file) => {
            this.thumpPath = file.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);

        this.file = files[0];
    }

    actionUploadImage(type): void {
        if (!super.isNullOrUndefined(this.file)) {
            this.commentService.upload(this.file, this.modelList[0].id).subscribe({
                next: data => {
                    this.actionList();
                    super.closeModal();
                    this.actionClear();
                    if (type === 'create') {
                        super.showMessage('Sucesso', 'Comentário cadastrado com sucesso!', 'success');
                    } else {
                        super.showMessage('Sucesso', 'Comentário alterado com sucesso!', 'success');
                    }
                }, error: err => super.onError(err)
            });
        } else {
            this.actionList();
            super.closeModal();
            this.actionClear();
            if (type === 'create') {
                super.showMessage('Sucesso', 'Comentário cadastrado com sucesso!', 'success');
            } else {
                super.showMessage('Sucesso', 'Comentário alterado com sucesso!', 'success');
            }
        }
    }

    actionClear(): void {
        this.modelFather = new CommentModel();
        this.modelList = [];
        this.modelIndex = 0;
        this.file = null;
        this.thumpPath = '';
    }

    actionSelectModel(index: number): void {
        this.modelIndex = index;
    }
}
