import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class TagService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    search(page: number = 1, model): any {
        const url = `${this.BASE_URL}/tag/search/${page}`;
        return this.http.post(url, model);
    }

    get(id: string): any {
        const url = `${this.BASE_URL}/tag/${id}`;
        return this.http.get(url);
    }

    getTypes(): any {
        const url = `${this.BASE_URL}/tag/type`;
        return this.http.get(url);
    }

    create(model): any {
        const url = `${this.BASE_URL}/tag/create`;
        return this.http.post(url, model);
    }

    update(model, id): any {
        const url = `${this.BASE_URL}/tag/update/${id}`;
        return this.http.put(url, model);
    }

    delete(id: string, lang: string): any {
        const url = `${this.BASE_URL}/tag/${id}/${lang}`;
        return this.http.delete(url);
    }
}
