import {Challenge} from './challenge';

export class Startup {
    id: string;
    name: string;
    email: string;
    cellphone: string;
    startupName: string;
    site: string;
    linkedin: string;
    country: string;
    cep: string;
    address: string;
    number: number;
    neighborhood: string;
    city: string;
    uf: string;
    yearFoundation: number;
    stage: string;
    contributors: number;
    twitterPitch: string;
    hub: boolean;
    hubDescription: string;
    pitchDeck: string;
    icon: string;
    status: string;
    language: string;
    createdAt: Date;
    updatedAt: Date;
    challenge: Challenge;
}

export class StartupSearchResult {
    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPage: number;
    previousPage: boolean;
    nextPage: boolean;
    results: Startup[];

    constructor() {
        this.results = [];
    }
}

export class FilterStartup {
    name: string;
    email: string;
    stage: string;
    country: string;
    city: string;
    foundationYear: number;
    status: string;
    contributors: number;
    start: Date;
    end: Date;
    language: string;
}
