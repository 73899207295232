import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from '../model/user/user';

@Injectable({
    providedIn: 'root'
})
export class UserService extends ServiceBase {
    isAuthenticated = false;

    private userDataSource = new BehaviorSubject<User>(new User());
    user = this.userDataSource.asObservable();

    constructor(http: HttpClient) {
        super(http);
    }

    updateUserDataSource(user) {
        this.userDataSource.next(user);
    }

    checkAuthenticated() {
        const token = localStorage.getItem('token') as string;
        if (token != null) {
            this.isAuthenticated = true;
        }
        return this.isAuthenticated;
    }

    updateUser(user: User) {
        this.userDataSource.next(user);
    }

    getAuthenticate(model) {
        const url = `${this.BASE_URL}/user/authenticate`;
        return this.http.post(url, model);
    }

    getMe() {
        const url = `${this.BASE_URL}/user`;
        return this.http.get(url);
    }

    forgot(model) {
        const url = `${this.BASE_URL}/user/forgot`;
        return this.http.post(url, model);
    }

    search(page: number = 1, model) {
        const url = `${this.BASE_URL}/user/search/${page}`;
        return this.http.post(url, model);
    }

    get(id: string) {
        const url = `${this.BASE_URL}/user/detail/${id}`;
        return this.http.get(url);
    }

    create(model) {
        const url = `${this.BASE_URL}/user/create`;
        return this.http.post(url, model);
    }

    update(model) {
        const url = `${this.BASE_URL}/user/update`;
        return this.http.post(url, model);
    }

    delete(id: string) {
        const url = `${this.BASE_URL}/user/${id}`;
        return this.http.delete(url);
    }
}
