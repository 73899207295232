import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {HeaderComponent} from './views/header/header.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {SidebarComponent} from './views/sidebar/sidebar.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FaqComponent} from './views/help/faq/faq.component';
import {LogoutComponent} from './views/logout/logout.component';
import {LogService} from './service/log.service';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {AppDataService} from './service/app_data.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import {LoginComponent} from './views/login/login.component';
import {UserComponent} from './views/user/user.component';
import {HomeComponent} from './views/home/home.component';
import {NgxMaskModule} from 'ngx-mask';
import {BannersComponent} from './views/banners/banners.component';
import {EnterpriseComponent} from './views/enterprise/enterprise.component';
import {CommentsComponent} from './views/comments/comments.component';
import {StartupsComponent} from './views/startups/startups.component';
import {ChallengesComponent} from './views/challenges/challenges.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HeaderHttpInterceptor} from './base/header-interceptor';
import { TagsComponent } from './views/tags/tags.component';
import {ColorPickerModule} from "ngx-color-picker";

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HeaderComponent,
        SidebarComponent,
        FaqComponent,
        LogoutComponent,
        LoginComponent,
        UserComponent,
        HomeComponent,
        BannersComponent,
        EnterpriseComponent,
        CommentsComponent,
        StartupsComponent,
        ChallengesComponent,
        TagsComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        AngularSvgIconModule.forRoot(),
        SlickCarouselModule,
        NgxMaskModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgxPaginationModule,
        ColorPickerModule
    ],
    providers: [
        LogService,
        AppDataService,
        [
            {
                provide: HTTP_INTERCEPTORS,
                useClass: HeaderHttpInterceptor,
                multi: true
            }
        ],
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
