<nav id="sidebar">
  <ul class="list-unstyled components navbar-nav" id="accordionExample">
    <!--<div class="">
      <img src="assets/images/logo.png" class="d-block logo" alt="Minha Carreira" />
    </div>-->
    <div class="user d-lg-none">
      <!--<div class="text-center w-100 mb-2">
        <img src="https://via.placeholder.com/30">
      </div>-->
      <span>Olá, <strong>Fulano da Silva</strong></span>
    </div>
    <li class="nav-item active">
      <a href="javascript:void('Nota');" [routerLink]="['/']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/home.svg"></svg-icon></span>
        <span class="lbl"> Home</span>
      </a>
    </li>
    <li class="nav-item help-ts d-none">
      <a href="javascript:void('Ajuda');"  class="nav-link" data-toggle="collapse"
         data-target="#preContent" aria-expanded="false"
         aria-controls="preContent">
        <span class="ico"><svg-icon src="assets/svg/content.svg"></svg-icon></span>
        <span class="lbl"> Gestão de Conteudo pré-definido</span>
      </a>
      <div class="collapse" id="preContent" data-parent="#accordionExample">
        <!--<a href="javascript:void('Treinamento');" [routerLink]="['/faq']"
           class="collapse-item cursor text-white">
           <span class="ico empty"></span> Treinamento
        </a>-->
        <a href="javascript:void('Dúvidas');" [routerLink]="['/banner']" class="collapse-item cursor text-white">
          <span class="ico empty"></span>Banners
        </a>
      </div>
    </li>
    <li class="nav-item">
      <a href="javascript:void('Usuários');" [routerLink]="['/empresas']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/connection.svg"></svg-icon></span>
        <span class="lbl"> Empresas - Conexão</span>
      </a>
    </li>
    <li class="nav-item d-none">
      <a href="javascript:void('Usuários');" [routerLink]="['/comentarios']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/comments.svg"></svg-icon></span>
        <span class="lbl">Depoimentos</span>
      </a>
    </li>
    <!--<li class="nav-item">
      <a href="javascript:void('Usuários');" [routerLink]="['/startups']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/startups.svg"></svg-icon></span>
        <span class="lbl">Gestão de Startups</span>
      </a>
    </li>-->
    <li class="nav-item d-none">
      <a href="javascript:void('Usuários');" [routerLink]="['/tags']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/tag.svg"></svg-icon></span>
        <span class="lbl">Gestão de Tags</span>
      </a>
    </li>
    <li class="nav-item">
      <a href="javascript:void('Usuários');" [routerLink]="['/desafios']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/challenges.svg"></svg-icon></span>
        <span class="lbl">Gestão de Desafios</span>
      </a>
    </li>
    <li class="nav-item">
      <a href="javascript:void('Usuários');" [routerLink]="['/usuarios']" class="nav-link text-white"
         (click)="actionCloseCollapse()">
        <span class="ico"><svg-icon src="assets/svg/admin.svg"></svg-icon></span>
        <span class="lbl">Gestão de Usuários Admin</span>
      </a>
    </li>
    <li class="nav-item help-ts d-none">
      <a href="javascript:void('Ajuda');" class="nav-link" data-toggle="collapse"
         data-target="#help" aria-expanded="false"
         aria-controls="help">
        <span class="ico"><svg-icon src="assets/svg/interrogation-circle.svg"></svg-icon></span>
        <span class="lbl"> Ajuda</span>
      </a>
      <div class="collapse" id="help" data-parent="#accordionExample">
        <a href="javascript:void('Dúvidas');" [routerLink]="['/faq']" class="collapse-item cursor text-white">
          <span class="ico empty"></span> Dúvidas
        </a>
      </div>
    </li>
  </ul>
  <div class="logout d-lg-none">
    <a href="javascript:void('sair');" (click)="onLogout()">
      Sair
    </a>
  </div>

</nav>
