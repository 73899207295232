import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {StartupService} from '../../service/startup_model.service';
import {FilterStartup, Startup, StartupSearchResult} from '../../model/startup';
import {environment} from '../../../environments/environment';
import {LanguageModel, Stage} from "../../model/common_model";
import {CommonService} from "../../service/common.service";
import {Company} from "../../model/company";
import {TranslateService} from "@ngx-translate/core";
import {ExcelService} from "../../service/excel.service";

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-startups',
    templateUrl: './startups.component.html',
    styleUrls: ['./startups.component.scss']
})
export class StartupsComponent extends BaseComponent implements OnInit {

    result: StartupSearchResult = new StartupSearchResult();
    modelFilter: FilterStartup = new FilterStartup();
    model: Startup = new Startup();
    page = 1;
    languages: LanguageModel[] = [];
    listStage: Stage[] = [];
    stagesFilterSelected: Stage[] = [];
    file: File = null;
    thumpPath: string | ArrayBuffer;
    dropdownSettings = {
        singleSelection: true,
        idField: 'key',
        textField: 'stage',
        selectAllText: 'Selecionar Todos',
        unSelectAllText: 'Desmarcar Todos',
        itemsShowLimit: 3,
        searchPlaceholderText: 'Pesquisar',
        noDataAvailablePlaceholderText: 'Sem informação disponível',
        allowSearchFilter: true
    };

    dropdownEditSettings = {
        singleSelection: true,
        idField: 'key',
        textField: 'stage',
        searchPlaceholderText: 'Pesquisar',
        noDataAvailablePlaceholderText: 'Sem informação disponível',
        allowSearchFilter: true
    };

    constructor(public router: Router,
                public startupService: StartupService,
                public commonService: CommonService,
                public translate: TranslateService,
                public excelService: ExcelService) {
        super(router, translate,'modalStartupEdit');
    }

    ngOnInit(): void {
        this.setDatePicker();
        this.actionList();
        this.getStages();
        this.getLanguages();
    }

    getLanguages() {
        this.commonService.getLanguages().subscribe({
            next: data => {
                this.languages = data as LanguageModel[];
            }, error: err => super.onError(err)
        });
    }

    getStages(): void {
        this.commonService.getStages().subscribe({
            next: data => {
                this.listStage = data as Stage[];
            }, error: err => super.onError(err)
        });
    }

    setDatePicker() {
        this.modelFilter.start = moment().subtract(15, 'days').format();
        this.modelFilter.end = moment().format();
        $('.js-daterangepicker').daterangepicker({
            showDropdowns: false,
            startDate: moment().subtract(15, 'days'),
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.modelFilter.start = start.format();
            this.modelFilter.end = end.format();
        });
    }

    actionList(): void {
        this.modelFilter.stage = this.stagesFilterSelected.map(s => {
            return s.key;
        }).join('|');
        this.startupService.search(this.page, this.modelFilter).subscribe({
            next: data => {
                this.result = data as StartupSearchResult;
            }, error: err => super.onError(err)
        });
    }

    actionClearFilter(): void {
        this.modelFilter = new FilterStartup();
        this.setDatePicker();
        this.actionList();
    }

    pageChanged(event): void {
        this.page = event;
        this.actionList();
    }

    actionDetail(id: string): void {
        this.startupService.get(id).subscribe({
            next: data => {
                this.model = data as Startup;
                if (!this.isNullOrUndefined(this.model.icon)) {
                    this.thumpPath = this.model.icon;
                }
                this.showModal();
            }, error: err => super.onError(err)
        });
    }

    actionRemove(id: any): void {
        super.confirmMessage('Atenção', 'Deseja realmente remover esta Startup?', () => {
            this.startupService.delete(id).subscribe({
                next: data => {
                    this.actionList();
                    super.showMessage('Sucesso', 'Startup removida com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    getExcel(): void {
        this.excelService.getExcelStartup(this.modelFilter).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }

    actionSave(): void {
        let arrValidateFields = [];

        if (super.isNullOrUndefined(this.model.id)) {
            arrValidateFields = [
                {value: this.model.name, text: 'Nome,<br>'},
                {value: this.model.language, text: 'Idioma,<br>'},
                {value: this.model.contributors, text: 'Colaboradores,<br>'},
                {value: this.model.stage, text: 'Estágio,<br>'},
                {value: this.model.yearFoundation, text: 'Fundação,<br>'},
                {value: this.model.city, text: 'Cidade,<br>'},
                {value: this.model.uf, text: 'Estado,<br>'},
                {value: this.model.country, text: 'País,<br>'},
                {value: this.model.email, text: 'E-mail,<br>'},
                {value: this.file, text: 'Selecione um arquivo.'}
            ];
        } else {
            arrValidateFields = [
                {value: this.model.name, text: 'Nome,<br>'},
                {value: this.model.language, text: 'Idioma,<br>'},
                {value: this.model.contributors, text: 'Colaboradores,<br>'},
                {value: this.model.stage, text: 'Estágio,<br>'},
                {value: this.model.yearFoundation, text: 'Fundação,<br>'},
                {value: this.model.city, text: 'Cidade,<br>'},
                {value: this.model.uf, text: 'Estado,<br>'},
                {value: this.model.country, text: 'País,<br>'},
                {value: this.model.email, text: 'E-mail,<br>'},
            ];
        }

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (this.isNullOrUndefined(this.model.id)) {
            this.create();
        } else {
            this.update();
        }
    }

    create(): void {
        this.startupService.create(this.model).subscribe({
            next: data => {
                this.model = data as Startup;
                this.actionUploadImage('create');
            }, error: err => super.onError(err)
        });
    }

    update(): void {
        this.startupService.update(this.model).subscribe({
            next: data => {
                this.model = data as Startup;
                if (!super.isNullOrUndefined(this.file)) {
                    this.actionUploadImage('update');
                } else {
                    super.showMessage('Sucesso', 'Startup alterada com sucesso!', 'success');
                    this.actionList();
                    this.model = new Startup();
                    super.closeModal();
                }
            }, error: err => super.onError(err)
        });
    }

    actionUploadImage(type) {
        this.startupService.upload(this.file, this.model.id).subscribe({
            next: data => {
                this.actionList();
                this.model = new Startup();
                this.file = null;
                super.closeModal();
                if (type === 'create') {
                    super.showMessage('Sucesso', 'Startup cadastrada com sucesso!', 'success');
                } else {
                    super.showMessage('Sucesso', 'Startup alterada com sucesso!', 'success');
                }
            }, error: err => super.onError(err)
        });
    }

    handlerUpload(event) {
        const files = event.target.files;
        if (files.length > 0) {
            if (files[0].size > 5000000) {
                super.showMessage('Atenção', 'O arquivo não deve ultrapassar o tamanho de 5MB', 'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (file) => {
            this.thumpPath = file.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);

        this.file = files[0];
    }

    onClearModel(event: FocusEvent, str: string) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.model[str] = '';
            }
        });
    }
}
