import {Tag} from './challenge';

export class Company {
    id: string;
    name: string;
    highlight: boolean;
    description: string;
    icon: string;
    tagId: string;
    languageId: string;
    video: string;
    tag: Tag;
    site: string;
    createdAt: Date;
    createdById: string;
    updatedAt: Date;
    deletedAt: Date;
    deletedById: string;
    languages: Company[];
    activate: boolean;
}

export class CompanySearchResult {
    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPage: number;
    previousPage: boolean;
    nextPage: boolean;
    results: Company[];

    constructor() {
        this.results = [];
    }
}

export class FilterCompany {
    name: string;
    language: string;
    tagId: string;
    highlight: boolean;
    description: string;
}
