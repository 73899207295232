<section class="container">

    <div class="d-flex align-items-center ml-5 mt-4">
        <svg-icon class="svg" src="assets/svg/document-plus.svg"></svg-icon>
        <h1 class="title ml-4">Banners</h1>
    </div>

    <div class="row">
        <div class="col-sm-12 text-right">
            <a (click)="actionCreate()" href="javascript:void('');"
               class="btn btn-ico btn-sm  mt-3 mt-lg-0 btn-success mx-2 mb-3 mb-xs-0">
                <svg-icon class="svg-white" src="assets/svg/add.svg"></svg-icon>
                Novo Banner
            </a>
        </div>
    </div>

    <div class="card mt-3 bg-white mx-2">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <div class="input-material">
                        <input id="title" name="title" [(ngModel)]="modelFilter.title" class="form-control" type="text"
                               maxlength="200" autocomplete="off"
                               required/>
                        <label for="title">Título do Banner</label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    <div class="input-material">
                        <select id="commentStatus" [(ngModel)]="modelFilter.highlight" class="form-control" name="type">
                            <option [ngValue]="undefined">Todos</option>
                            <option selected [ngValue]="true">Ativo</option>
                            <option selected [ngValue]="false">Inativo</option>
                        </select>
                        <label for="commentStatus" class="mb-0 mr-3">Status</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-sm-12 text-right">
                    <a href="javascript:void('');" (click)="actionClearFilter()"
                       class="btn btn-ico btn-sm btn-danger mx-2 mb-3 mb-xs-0">
                        <svg-icon class="svg-white" src="assets/svg/delete.svg"></svg-icon>
                        Limpar Filtro
                    </a>
                    <a href="javascript:void('');" (click)="actionList()"
                       class="btn btn-ico btn-sm btn-info mx-2 mb-3 mb-xs-0">
                        <svg-icon class="svg-white" src="assets/svg/search.svg"></svg-icon>
                        Pesquisar
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="result?.results.length === 0">
        <div class="col-12">
            <p class="my-5 py-5 text-center">Nenhum registro encontrado.</p>
        </div>
    </div>
    <div class="row banner_row">
        <div *ngFor="let r of result?.results |
                            paginate: {
                             itemsPerPage: result.pageSize,
                             currentPage: result.currentPage,
                             totalItems: result.totalCount}" class="col-12 col-md-6 col-lg-4">
            <div class="card mt-4 bg-white mx-2">
                <div class="card-body">
                    <div class="row justify-content-around align-items-center p-3">
                        <div *ngIf="r.image" class="banner_thumb mr-3">
                            <img class="bannerPhotoPlaceholder" src="{{r.image}}" alt="">
                        </div>
                        <div class="enterprise_info w-100 mt-4">
                            <div class="row">
                                <div class="col-12">
                                    <b>Título:</b>
                                    <p class="mb-2 ml-1"> {{r.title}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <b>Status:</b>
                                    <p class="mb-2 ml-1"> {{r.highlight ? 'Ativo' : 'Inativo'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row w-100">
                            <div class="col-12">
                                <div class="d-flex justify-content-end">
                                    <a href="javascript:void('');"
                                       class="btn btn-ico btn-ico-no-marg btn-sm btn-warning mx-2 mb-3 mb-xs-0"
                                       (click)="actionDetail(r.id)">
                                        <svg-icon class="svg-white" src="assets/svg/edit.svg"></svg-icon>
                                    </a>
                                    <div class="dropdown show">
                                        <a href="javascript:void('');"
                                           class="btn btn-ico btn-ico-no-marg btn-sm btn-danger mx-2 mb-3 mb-xs-0 dropdown-toggle"
                                           role="button" id="dropdownMenuLink" data-toggle="dropdown"
                                           aria-haspopup="true" aria-expanded="false">
                                            <svg-icon class="svg-white" src="assets/svg/delete.svg"></svg-icon>
                                        </a>

                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <a href="javascript:void('');"
                                               class="dropdown-item" *ngFor="let it of r.languages"
                                               (click)="actionRemove(r.id, it.languageId)">
                                                {{getLanguageName(it.languageId, this.languages)}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>


<div class="modal fade" id="modalBannerAdd" tabindex="-1" role="dialog" aria-labelledby="moreLabel"
     aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <strong class="text-primary">Novo Banner</strong>
                </h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon class="svg-icon-primary" src="assets/svg/fechar.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body  px-0 pb-0">
                <div class="card p-3 row d-flex flex-column justify-content-center align-items-center">
                    <input type="file" class="BannerFile">
                    <svg-icon class="svg" src="assets/svg/upload.svg"></svg-icon>
                    <p class="text-primary">(Uploads de imagens de até 50mb)</p>
                    <div class="row mt-3">
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="input-material">
                                <input id="bannerTitle" class="form-control" type="text" maxlength="200"
                                       autocomplete="off" required/>
                                <label for="bannerTitle">Titulo do Banner</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="input-material">
                                <input id="bannerDesc" class="form-control" type="text" maxlength="200"
                                       autocomplete="off" required/>
                                <label for="bannerDesc">Texto do Banner</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="input-material">
                                <select id="bannerStatus" class="form-control" name="type">
                                    <option selected [ngValue]="''">Sim</option>
                                    <option selected [ngValue]="''">Não</option>
                                </select>
                                <label for="bannerStatus" class="mb-0 mr-3">Video</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="input-material">
                                <input id="bannerIdiom" class="form-control" type="text" maxlength="200"
                                       autocomplete="off" required/>
                                <label for="bannerIdiom">Idioma do Banner</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="input-material">
                                <select id="bannerHighlight" class="form-control" name="type">
                                    <option selected [ngValue]="''">Sim</option>
                                    <option selected [ngValue]="''">Não</option>
                                </select>
                                <label for="bannerHighlight" class="mb-0 mr-3">Destaque</label>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="editBannerModal" tabindex="-1" role="dialog"
     aria-labelledby="moreLabel"
     aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <strong class="text-primary">Banner</strong>
                </h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon class="svg-icon-primary" src="assets/svg/fechar.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body px-0 pb-0">
                <div class="card mt-2 bg-white mx-2">
                    <div class="card-body">
                        <div class="justify-content-center d-flex flex-column mb-2">
                            <strong *ngIf="!isNullOrUndefined(thumpPath)" class="text-primary mb-2">Banner
                                upload</strong>
                            <input class="d-none" id="uploadBanner" type="file" accept=".jpg,.png,.jpeg,.JPEG,.JPG,.PNG"
                                   (change)="handlerUpload($event)">

                            <label for="uploadBanner"
                                   class="d-flex flex-column align-items-center w-100">
                                <span class="banner_thumb banner_thumb-large position-relative" *ngIf="!isNullOrUndefined(thumpPath)">
                                    <img src="{{thumpPath}}" alt="Imagem"/>
                               <svg-icon *ngIf="!isNullOrUndefined(thumpPath)"
                                         class="editIcon editPhotoBanner"
                                         src="assets/svg/edit.svg"></svg-icon>
                                </span>


                                <svg-icon *ngIf="isNullOrUndefined(thumpPath)" class="svg"
                                          src="assets/svg/upload.svg"></svg-icon>
                                <span *ngIf="isNullOrUndefined(thumpPath)" class="text-primary">(Uploads de imagens de até
                                    10mb)</span>
                            </label>

                        </div>
                        <div class="justify-content-center d-flex flex-column mt-5 mb-2">
                            <strong *ngIf="!isNullOrUndefined(qrCode)" class="text-primary mb-2">QrCode upload</strong>
                            <div class="d-flex flex-column flex-lg-row flex align-items-center" style="gap: 20px">
                                <div>
                                    <input id="uploadQrCode" class="d-none" type="file"
                                           accept=".jpg,.png,.jpeg,.JPEG,.JPG,.PNG"
                                           (change)="handlerQrUpload($event)">

                                    <label for="uploadQrCode" class="d-flex flex-column align-items-center">
                                        <span class="banner_qrCode banner_qrCode-large position-relative"
                                              *ngIf="!isNullOrUndefined(qrCode)">
                                            <img src="{{qrCode}}" alt="Imagem"/>
                                            <svg-icon *ngIf="!isNullOrUndefined(qrCode)" class="editIcon editPhotoBanner"
                                                      src="assets/svg/edit.svg"></svg-icon>
                                        </span>


                                        <svg-icon *ngIf="isNullOrUndefined(qrCode)" class="svg"
                                                  src="assets/svg/qrcode.svg"></svg-icon>
                                        <span *ngIf="isNullOrUndefined(qrCode)" class="text-primary text-center">(Uploads de imagens de até
                                    10mb)</span>
                                    </label>
                                </div>
                                <div class="flex-grow-1 w-100">
                                    <div class="input-material">
                                        <select [(ngModel)]="modelFather.highlight" id="status"
                                                class="form-control"
                                                name="status">
                                            <option selected [ngValue]="true">Ativo</option>
                                            <option selected [ngValue]="false">Inativo</option>
                                        </select>
                                        <label for="status" class="mb-0 mr-3">Status</label>
                                    </div>
                                    <div class="input-material">
                                        <input [(ngModel)]="modelFather.title" id="name" name="name"
                                               class="form-control"
                                               type="text" maxlength="150"
                                               autocomplete="off" required/>
                                        <label for="name">Título</label>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div class="row">
                            <ul class="col-12 d-flex justify-content-center nav nav-tabs mb-3">
                                <li class="nav-item" *ngFor="let m of modelList; let i = index">
                                    <a class="nav-link pe-auto cursor-pointer" [ngClass]="{'active': modelIndex === i}"
                                       (click)="actionSelectModel(i)">{{getLanguageName(m.languageId, languages)}}</a>
                                </li>
                            </ul>
                            <div *ngIf="!isNullOrUndefined(modelList)" class="col-12">
                                <div class="row">
                                    <div *ngIf="isNullOrUndefined(modelList[modelIndex].id)"
                                         class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="d-flex" style="gap: 15px; align-items: center;">
                                            <label class="mb-0 duk-check">
                                                <input [(ngModel)]="modelList[modelIndex].activate"
                                                       [ngModelOptions]="{standalone: true}"
                                                       id="activeCreate"
                                                       name="activeCreate"
                                                       type="checkbox"/>
                                                <span class="checkmark">
                                                    <svg-icon src="assets/svg/check.svg"></svg-icon>
                                                </span>
                                                <span>Ativar</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="input-material">
                                            <input [(ngModel)]="modelList[modelIndex].video" id="video" name="video"
                                                   class="form-control"
                                                   type="text" maxlength="255"
                                                   [disabled]="!modelList[modelIndex].activate"
                                                   autocomplete="off" required/>
                                            <label for="name">Vídeo</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="input-material">
                                    <textarea [(ngModel)]="modelList[modelIndex].text" name="textEdit" id="textEdit"
                                              class="form-control"
                                              [disabled]="!modelList[modelIndex].activate"
                                              type="text" maxlength="500" rows="3"
                                              autocomplete="off" required></textarea>
                                            <label for="textEdit">Texto</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <a href="javascript:void('');" (click)="actionSave()"
                           class="btn btn-ico btn-sm btn-success mb-3 mb-xs-0">
                            <svg-icon class="svg-white" src="assets/svg/disquet.svg"></svg-icon>
                            Salvar
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
