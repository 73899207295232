import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {FaqComponent} from './views/help/faq/faq.component';
import {LogoutComponent} from './views/logout/logout.component';
import {LoginComponent} from './views/login/login.component';
import {UserComponent} from './views/user/user.component';
import {HomeComponent} from './views/home/home.component';
import {EnterpriseComponent} from './views/enterprise/enterprise.component';
import {CommentsComponent} from './views/comments/comments.component';
import {StartupsComponent} from './views/startups/startups.component';
import {ChallengesComponent} from './views/challenges/challenges.component';
import {BannersComponent} from './views/banners/banners.component';
import {AuthGuard} from './service/auth_guard.service';
import {TagsComponent} from "./views/tags/tags.component";

const routes: Routes = [
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'logout', component: LogoutComponent
  },
  {
    path: '', component: MainComponent,
    children: [
      {path: '', component: HomeComponent},
      {path: 'usuarios', component: UserComponent},
      {path: 'faq', component: FaqComponent},
      {path: 'banner', component: BannersComponent},
      {path: 'empresas', component: EnterpriseComponent},
      {path: 'comentarios', component: CommentsComponent},
      {path: 'tags', component: TagsComponent},
      {path: 'startups', component: StartupsComponent},
      {path: 'desafios', component: ChallengesComponent},
    ], canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
