<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<section class="container">

    <div class="d-flex align-items-center ml-5 mt-4">
        <svg-icon class="svg" src="assets/svg/comments.svg"></svg-icon>
        <h1 class="title ml-4">Depoimentos</h1>
    </div>

    <div class="row">
        <div class="col-sm-12 text-right">
            <a (click)="actionCreate()" href="javascript:void('');"
               class="btn btn-ico btn-sm  mt-3 mt-lg-0 btn-success mx-2 mb-3 mb-xs-0">
                <svg-icon class="svg-white" src="assets/svg/add.svg"></svg-icon>
                Novo Depoimento
            </a>
        </div>
    </div>

    <div class="card mt-3 bg-white mx-2 mb-3">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="input-material">
                        <input id="commentUserName" [(ngModel)]="modelFilter.name" class="form-control" type="text"
                               maxlength="200" autocomplete="off"
                               required/>
                        <label for="commentUserName">Nome do Usuário</label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="input-material">
                        <select id="commentStatus" [(ngModel)]="modelFilter.highlight" class="form-control" name="type">
                            <option selected [ngValue]="undefined">Todos</option>
                            <option selected [ngValue]="true">Sim</option>
                            <option selected [ngValue]="false">Não</option>
                        </select>
                        <label for="commentStatus" class="mb-0 mr-3">Destaque</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-sm-12 text-right">
                    <a href="javascript:void('');" (click)="actionClearFilter()"
                       class="btn btn-ico btn-sm btn-danger mx-2 mb-3 mb-xs-0">
                        <svg-icon class="svg-white" src="assets/svg/delete.svg"></svg-icon>
                        Limpar Filtro
                    </a>
                    <a href="javascript:void('');" (click)="actionList()"
                       class="btn btn-ico btn-sm btn-info mx-2 mb-3 mb-xs-0">
                        <svg-icon class="svg-white" src="assets/svg/search.svg"></svg-icon>
                        Pesquisar
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div *ngFor="let r of result?.results |
                                paginate: {
                                 itemsPerPage: result.pageSize,
                                 currentPage: result.currentPage,
                                 totalItems: result.totalCount}" class="col-md-4 col-12">
            <div class="card mt-3 p-3 bg-white mx-2">
                <div class="d-flex flex-column">
                    <div class="row justify-content-around align-items-center p-3">
                        <div *ngIf="r.photo" class="enterprise_icon mr-3">
                            <img class="commentPhotoPlaceholder" src="{{r.photo}}" alt="">
                        </div>
                        <div class="enterprise_info w-100">
                            <div class="row">
                                <div class="col-12">
                                    <b>Nome:</b>
                                    <p class="ml-1"> {{r.name}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <b>Empresa:</b>
                                    <p class="ml-1"> {{r.companyName}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <b>Destaque:</b>
                                    <p class="ml-1"> {{r.highlight ? 'Sim' : 'Não'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row w-100">
                    <div class="col-12">
                        <div class="d-flex justify-content-end">
                            <a href="javascript:void('');"
                               class="btn btn-ico btn-ico-no-marg btn-sm btn-warning mx-2 mb-3 mb-xs-0"
                               (click)="actionDetail(r.id)">
                                <svg-icon class="svg-white" src="assets/svg/edit.svg"></svg-icon>
                            </a>
                            <div class="dropdown show">
                                <a href="javascript:void('');"
                                   class="btn btn-ico btn-ico-no-marg btn-sm btn-danger mx-2 mb-3 mb-xs-0 dropdown-toggle"
                                   role="button" id="dropdownMenuLink" data-toggle="dropdown"
                                   aria-haspopup="true" aria-expanded="false">
                                    <svg-icon class="svg-white" src="assets/svg/delete.svg"></svg-icon>
                                </a>

                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <a href="javascript:void('');"
                                       class="dropdown-item" *ngFor="let it of r.languages"
                                       (click)="actionRemove(r.id, it.languageId)">
                                        {{getLanguageName(it.languageId, this.languages)}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-3 mx-2 bg-white p-3">
        <div class="text-center">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>

</section>


<div class="modal fade" id="modalCommentEdit" tabindex="-1" role="dialog" aria-labelledby="moreLabel"
     aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <strong *ngIf="!isNullOrUndefined(modelFather?.id)" class="text-primary">Editar Depoimento</strong>
                    <strong *ngIf="isNullOrUndefined(modelFather?.id)" class="text-primary">Cadastrar
                        Depoimento</strong>
                </h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon class="svg-icon-primary" src="assets/svg/fechar.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body px-0 pb-0">
                <div class="card mt-3 bg-white mx-2">
                    <div class="card-body">
                        <div class="row justify-content-center d-flex flex-column align-items-center mt-2 mb-2">
                            <img class="commentPhoto" *ngIf="!isNullOrUndefined(thumpPath)" style="max-height: 210px;"
                                 src="{{thumpPath}}"
                                 alt="Responsive image">
                            <svg-icon *ngIf="!isNullOrUndefined(thumpPath)" class="editIcon editPhoto"
                                      src="assets/svg/edit.svg"></svg-icon>
                            <input type="file" class="BannerFile" onclick="this.value = null"
                                   (change)="handlerUpload($event)">
                            <svg-icon *ngIf="isNullOrUndefined(thumpPath)" class="svg"
                                      src="assets/svg/upload.svg"></svg-icon>
                            <p *ngIf="isNullOrUndefined(thumpPath)" class="text-primary">(Uploads de imagens de até
                                5mb)</p>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <div class="input-material">
                                    <select [(ngModel)]="modelFather.highlight" id="highlight"
                                            class="form-control"
                                            name="highlight">
                                        <option selected [ngValue]="true">Sim</option>
                                        <option selected [ngValue]="false">Não</option>
                                    </select>
                                    <label for="highlight" class="mb-0 mr-3">Destaque</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <span id="product" class="badge-rounded"> ? </span>
                                <div class="input-material">
                                    <input [(ngModel)]="modelFather.name" id="name" name="name"
                                           class="form-control"
                                           type="text" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="name">Nome</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="input-material">
                                    <input [(ngModel)]="modelFather.companyName"
                                           name="companyName"
                                           id="companyName" class="form-control" type="text"
                                           maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="companyName">Empresa</label>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <ul class="col-12 d-flex justify-content-center nav nav-tabs mb-3">
                                <li class="nav-item" *ngFor="let m of modelList; let i = index">
                                    <a class="nav-link pe-auto cursor-pointer" [ngClass]="{'active': modelIndex === i}"
                                       (click)="actionSelectModel(i)">{{getLanguageName(m.languageId, languages)}}</a>
                                </li>
                            </ul>
                            <div *ngIf="!isNullOrUndefined(modelList)" class="col-12">
                                <div class="row">
                                    <div *ngIf="isNullOrUndefined(modelList[modelIndex].id)"
                                         class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="d-flex" style="gap: 15px; align-items: center;">
                                            <label class="mb-0 duk-check">
                                                <input [(ngModel)]="modelList[modelIndex].activate"
                                                       [ngModelOptions]="{standalone: true}"
                                                       id="activeCreate"
                                                       name="activeCreate"
                                                       type="checkbox"/>
                                                <span class="checkmark">
                                                    <svg-icon src="assets/svg/check.svg"></svg-icon>
                                                </span>
                                                <span>Ativar</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-6">
                                        <div class="input-material">
                                            <input [(ngModel)]="modelList[modelIndex].jobRole" name="jobRole"
                                                   id="jobRole"
                                                   class="form-control"
                                                   [disabled]="!modelList[modelIndex].activate"
                                                   type="text" maxlength="200"
                                                   autocomplete="off" required/>
                                            <label for="jobRole">Função</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-6">
                                        <div class="input-material">
                                            <input [(ngModel)]="modelList[modelIndex].video" name="video" id="video"
                                                   class="form-control"
                                                   type="text"
                                                   maxlength="200"
                                                   [disabled]="!modelList[modelIndex].activate"
                                                   autocomplete="off"/>
                                            <label for="video">Vídeo</label>
                                        </div>
                                        <span id="product" class="badge-rounded"> ? </span>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="input-material">
                                    <textarea [(ngModel)]="modelList[modelIndex].text" name="textEdit" id="textEdit"
                                              class="form-control"
                                              [disabled]="!modelList[modelIndex].activate"
                                              type="text" maxlength="500" rows="3"
                                              autocomplete="off" required></textarea>
                                            <label for="textEdit">Depoimento</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-sm-12 text-right">
                                <a href="javascript:void('');" (click)="actionSave()"
                                   class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0">
                                    <svg-icon class="svg-white" src="assets/svg/disquet.svg"></svg-icon>
                                    Salvar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
