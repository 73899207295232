import {Component, OnInit} from '@angular/core';
import {FilterUser, User, UserSearchResult} from '../../model/user/user';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import {environment} from '../../../environments/environment';
import {TranslateService} from "@ngx-translate/core";
import {ExcelService} from "../../service/excel.service";

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseComponent implements OnInit {

    result: UserSearchResult = new UserSearchResult();
    modelFilter: FilterUser = new FilterUser();
    model: User = new User();
    page = 1;

    constructor(public router: Router,
                public userService: UserService,
                public translate: TranslateService,
                public excelService: ExcelService) {
        super(router, translate, 'modalUserEdit');
    }

    ngOnInit(): void {
        this.actionList();
    }

    actionList(): void {
        this.userService.search(this.page, this.modelFilter).subscribe({
            next: data => {
                this.result = data as UserSearchResult;
            }, error: err => super.onError(err)
        });
    }

    actionClearFilter(): void {
        this.modelFilter = new FilterUser();
        this.actionList();
    }

    pageChanged(event): void {
        this.page = event;
        this.actionList();
    }

    getExcel(): void {
        this.excelService.getExcelUser(this.modelFilter).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }

    actionDetail(id: string): void {
        this.userService.get(id).subscribe({
            next: data => {
                this.showModal();
                this.model = data as User;
            }, error: err => super.onError(err)
        });
    }

    actionSave(): void {
        const arrValidateFields = [
            {value: this.model.name, text: 'Nome,<br>'},
            {value: this.model.email, text: 'Email,<br>'},
            {value: this.model.profile, text: 'Perfil,<br>'},
        ];

        if (!super.isEMailValid(this.model.email)) {
            arrValidateFields.push({value: null, text: 'Formato de E-mail inválido.<br>'});
        }

        if (super.isNullOrUndefined(this.model.id) && super.isNullOrUndefined(this.model.password)) {
            arrValidateFields.push({value: null, text: 'Senha.<br>'});
        }

        if (super.isNullOrUndefined(this.model.id) && this.model.password !== this.model.confirmPassword) {
            arrValidateFields.push({value: null, text: 'Senhas não conferem.<br>'});
        }

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        if (this.isNullOrUndefined(this.model.id)) {
            this.create();
        } else {
            this.update();
        }
    }

    create(): void {
        this.userService.create(this.model).subscribe({
            next: data => {
                this.model = new User();
                this.actionList();
                super.closeModal();
                super.showMessage('Sucesso', 'Usuário cadastrado com sucesso!', 'success');
            }, error: err => super.onError(err)
        });
    }

    update(): void {
        this.userService.update(this.model).subscribe({
            next: data => {
                this.model = new User();
                this.actionList();
                super.closeModal();
                super.showMessage('Sucesso', 'Usuário alterado com sucesso!', 'success');
            }, error: err => super.onError(err)
        });
    }

    actionRemove(id: any): void {
        super.confirmMessage('Atenção', 'Deseja realmente remover este usuário?', () => {
            this.userService.delete(id).subscribe({
                next: data => {
                    this.actionList();
                    super.showMessage('Sucesso', 'Usuário removido com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    actionCreate(): void {
        this.model = new User();
        super.showModal();
    }

    getProfile(p: string): string {
        switch (p) {
            case 'admin':
                return 'Administrador';
            default:
                return '';
        }
    }

}
