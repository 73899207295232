import {Startup} from './startup';

export class Challenge {
    id: string;
    title: string;
    description: string;
    photo: string;
    start: Date;
    end: Date;
    video: string;
    tagId: string;
    highlight: boolean;
    languageId: string;
    problem: string;
    objective: string;
    link: string;
    createdAt: Date;
    updatedAt: Date;
    startups: Startup[];
    tag: Tag;
    languages: Challenge[];
    activate: boolean;

    constructor() {
        this.startups = [];
    }
}

export class ChallengeSearchResult {
    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPage: number;
    previousPage: boolean;
    nextPage: boolean;
    results: Challenge[];

    constructor() {
        this.results = [];
    }
}

export class FilterChallenge {
    title: string;
    description: string;
    tag: string;
    /*start: Date;
    end: Date;*/
    language: string;
    tagId: string;
}

export class Tag {
    id: string;
    languageId: string;
    name: string;
}
