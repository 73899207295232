import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2';
import {LanguageModel} from '../model/common_model';
import {Tag} from '../model/tag';

declare var $: any;

export class BaseComponent {

    constructor(public router: Router,
                public translate: TranslateService,
                public modalName: string = 'modalCompanyEdit') {
    }

    labels: any = {
        previousLabel: ' ',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    setToken(token: string): void {
        localStorage.setItem('token', token);
    }

    getBaseURL(): any {
        return environment.base_url;
    }

    switchLanguage(language: string) {
        if (language === 'pt-BR') {
            language = 'pt';
            this.setCulture('pt-BR');
        } else if (language === 'en-US') {
            language = 'en';
            this.setCulture('en-US');
        } else if (language === 'es-ES') {
            language = 'es';
            this.setCulture('es-ES');
        }
        this.translate.use(language);
    }

    setCulture(culture: string) {
        localStorage.setItem('culture', culture);
    }

    showMessage(t: string, m: string, type: any = null): void {
        Swal.fire({
            title: t,
            html: m,
            icon: type,
            confirmButtonColor: '#221d55',
        });
    }

    showModal() {
        $('#' + this.modalName).modal('show');
    }

    closeModal() {
        $('#' + this.modalName).modal('hide');
    }

    normalizeModels(modelList: any, languages: any): any {
        for (const m of modelList) {
            m.activate = true;
        }

        for (const lng of languages) {
            const exists = modelList.find(x => x.languageId === lng.key);
            if (this.isNullOrUndefined(exists)) {
                const b = new Tag();
                b.languageId = lng.key;
                b.activate = false;
                modelList.push(b);
            }
        }
    }

    confirmMessage(t: string, m: string, callback: any = null): void {
        Swal.fire({
            title: t,
            text: m,
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            confirmButtonColor: '#221d55',
            cancelButtonColor: '#dc3545'
        }).then((result) => {
            if (!this.isNullOrUndefined(result.value) && result.value === true) {
                if (callback != null) {
                    callback();
                }
            }
        });
    }

    onError(error): any {
        if (error.status === 401) {
            this.router.navigate(['/login']);
            return;
        }

        // const e = JSON.parse(error['error']);

        if (error.status === 400) {
            this.showMessage('Atenção', error.error.message, 'warning');
            return;
        }

        if (error.status === 500) {
            this.showMessage('Atenção', 'Ocorreu um erro.', 'warning');
            return;
        }
    }

    isNullOrUndefined(value): any {
        return typeof value === 'undefined' || value == null || value.length === 0;
    }

    validateField(obj): any {
        let strError = ``;
        obj.map((e) => {
            if (this.isNullOrUndefined(e.value)) {
                strError += `${e.text}`;
            }
        });
        return strError;
    }

    isEMailValid(strEmail): any {
        const str = strEmail;
        const exp = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
        const testResult = exp.test(str);
        if (!testResult) {
            this.showMessage('Atenção', 'Informe um e-mail válido para prosseguir.', 'warning');
        }
        return testResult;
    }

    toggleLoader(b: boolean): void {
        if (!b) {
            $('#loaderBox').fadeOut();
        } else {
            $('#loaderBox').fadeIn();
        }
    }

    onLogout(): void {
        this.confirmMessage('Atenção', 'Deseja realmente sair?', () => {
            this.router.navigate(['/logout']);
        });
    }

    handlerViewPassword(id = 'password'): void {
        const input = document.querySelector('#' + id);
        if (input.getAttribute('type') === 'password') {
            input.setAttribute('type', 'text');
        } else {
            input.setAttribute('type', 'password');
        }
    }

    handlerViewPasswordIcon(id = 'password'): boolean {
        // const input = document.querySelector('#password');
        const input = document.querySelector('#' + id);
        return input.getAttribute('type') === 'password';
    }

    objLocaleDateRangePicker() {
        return {
            customRangeLabel: 'Personalizar',
            format: 'DD/MM/YYYY',
            separator: ' - ',
            applyLabel: 'Aplicar',
            cancelLabel: 'Cancelar',
            daysOfWeek: [
                'Dom',
                'Seg',
                'Ter',
                'Qua',
                'Qui',
                'Sex',
                'Sab'
            ],
            monthNames: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ],
            firstDay: 1
        };
    }

    getLanguageName(lng: string, languages: LanguageModel[] = []): string {
        if (languages.length === 0) {
            return '';
        }
        const l = languages.find(a => a.key === lng);
        if (this.isNullOrUndefined(l)) {
            return '';
        }
        return l.value;
    }

    downloadHandlerFile(e) {
        $('#btnDownload').attr('href', e);
        setTimeout(() => {
            $('#btnDownload')[0].click();
        });
    }

}
