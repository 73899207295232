import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {CommonService} from '../../service/common.service';
import {BannerService} from '../../service/banner.service';
import {LanguageModel} from '../../model/common_model';
import {BannerSearchResult, FilterBanner, Banner} from '../../model/banner';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Component({
    selector: 'app-banners',
    templateUrl: './banners.component.html',
    styleUrls: ['./banners.component.scss']
})
export class BannersComponent extends BaseComponent implements OnInit, AfterViewInit {

    result: BannerSearchResult = new BannerSearchResult();
    modelFilter: FilterBanner = new FilterBanner();
    modelList: Banner[] = [];
    modelFather: Banner = new Banner();
    modelIndex = 0;
    page = 1;
    file: File = null;
    fileQrCode: File = null;
    thumpPath: string | ArrayBuffer;
    qrCode: string | ArrayBuffer;
    languages: LanguageModel[] = [];

    constructor(public router: Router,
                public bannerService: BannerService,
                public translate: TranslateService,
                public commonService: CommonService) {
        super(router, translate, 'editBannerModal');
    }

    ngOnInit(): void {
        this.getLanguages();
        this.actionList();
    }

    ngAfterViewInit(): void {
        this.popoverStruct('product',
            'Link do video',
            'Este campo devera conter o link do video do youtube, como no exemplo a seguir.');
    }

    popoverStruct(id: string, title: string, content: string): any {
        return $('#' + id).popover({
            title: '',
            content: 'That',
            trigger: 'hover',
            placement: 'right',
            template: '<div class="popover alert-error">' +
                '<div class="arrow"></div>' +
                '<div class="popover-inner">' +
                '<div class="popover-content">' +
                '<div class="border-right-custom p-2">' +
                '<p class="mb-0 text-uppercase font-weight-bold size-12">' + title + '</p>' +
                '<p class="info size-10"> ' + content + ' </p>' +
                '<img src="assets/images/video.jpg"> ' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</div>',
        });
    }

    getLanguages(): void {
        this.commonService.getLanguages().subscribe({
            next: data => {
                this.languages = data as LanguageModel[];
            }, error: err => super.onError(err)
        });
    }

    actionList(): void {
        this.bannerService.search(this.page, this.modelFilter).subscribe({
            next: data => {
                this.result = data as BannerSearchResult;
            }, error: err => super.onError(err)
        });
    }

    actionClearFilter(): void {
        this.modelFilter = new FilterBanner();
        this.actionList();
    }

    pageChanged(event): void {
        this.page = event;
        this.actionList();
    }

    actionDetail(id: string): void {
        this.actionClear();
        this.bannerService.get(id).subscribe({
            next: data => {
                this.modelList = data as Banner[];
                this.thumpPath = this.modelList[0].image;
                this.qrCode = this.modelList[0].qrCode;
                this.modelFather = this.modelList[0];

                super.normalizeModels(this.modelList, this.languages);

                this.showModal();
            }, error: err => super.onError(err)
        });
    }

    actionSave(): void {
        let arrValidateFields = [];

        if (super.isNullOrUndefined(this.modelFather.id)) {
            arrValidateFields = [
                {value: this.file, text: 'Selecione um arquivo para o banner <br>'},
                {value: this.fileQrCode, text: 'Selecione um arquivo para o qrCode <br>'},
                {value: this.modelFather.highlight, text: 'Status <br>'},
                {value: this.modelFather.title, text: 'Título <br>'}
            ];
        } else {
            arrValidateFields = [
                {value: this.modelFather.highlight, text: 'Status <br>'},
                {value: this.modelFather.title, text: 'Título <br>'}
            ];
        }

        for (const m of this.modelList.filter(x => x.activate)) {
            arrValidateFields.push({
                value: m.text,
                text: `Texto ${this.getLanguageName(m.languageId, this.languages)}, <br>`
            });
        }

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        for (const m of this.modelList) {
            m.highlight = this.modelFather.highlight;
            m.title = this.modelFather.title;
        }

        if (this.isNullOrUndefined(this.modelFather.id)) {
            if (this.modelList.filter(x => x.activate).length === 0) {
                this.showMessage('Atenção', `Preencha ao menos 1 idioma`, 'warning');
                return;
            }
            this.create();
        } else {
            this.update();
        }
    }

    create(): void {
        this.bannerService.create(this.modelList.filter(x => x.activate)).subscribe({
            next: data => {
                this.modelList = data as Banner[];
                this.actionUploadImage('create');
            }, error: err => super.onError(err)
        });
    }

    update(): void {
        this.bannerService.update(this.modelList.filter(x => x.activate), this.modelFather.id).subscribe({
            next: data => {
                this.modelList = data as Banner[];
                if (!super.isNullOrUndefined(this.file)) {
                    this.actionUploadImage('update');
                } else if (!super.isNullOrUndefined(this.fileQrCode)) {
                    this.actionUploadQrCode('update');
                } else {
                    super.showMessage('Sucesso', 'Banner alterado com sucesso!', 'success');
                    this.updateList();
                }
            }, error: err => super.onError(err)
        });
    }

    actionRemove(id: any, lang: string): void {
        super.confirmMessage('Atenção', `Deseja realmente remover o banner ${super.getLanguageName(lang, this.languages)}?`, () => {
            this.bannerService.delete(id, lang).subscribe({
                next: data => {
                    this.actionList();
                    super.showMessage('Sucesso', 'Banner removido com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    actionCreate(): void {
        this.actionClear();

        for (const l of this.languages) {
            const c = new Banner();
            c.languageId = l.key;
            c.activate = false;
            this.modelList.push(c);
        }

        super.showModal();
    }

    handlerUpload(event): void {
        const files = event.target.files;
        if (files.length > 0) {
            if (files[0].size > 10000000) {
                super.showMessage('Atenção', 'O arquivo não deve ultrapassar o tamanho de 10MB', 'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (file) => {
            this.thumpPath = file.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);

        this.file = files[0];
    }

    handlerQrUpload(event): void {
        const files = event.target.files;
        if (files.length > 0) {
            if (files[0].size > 10000000) {
                super.showMessage('Atenção', 'O arquivo não deve ultrapassar o tamanho de 10MB', 'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (file) => {
            this.qrCode = file.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);

        this.fileQrCode = files[0];
    }

    actionUploadImage(type): void {
        if (!super.isNullOrUndefined(this.file)) {
            this.bannerService.upload(this.file, `/banner/${this.modelList[0].id}/upload`).subscribe({
                next: data => {
                    if (!this.isNullOrUndefined(this.fileQrCode)) {
                        this.actionUploadQrCode(type);
                    } else {
                        this.updateList();
                        super.showMessage('Sucesso', 'Banner alterado com sucesso!', 'success');
                    }
                }, error: err => super.onError(err)
            });
        } else {
            this.updateList();
            if (type === 'create') {
                super.showMessage('Sucesso', 'Banner cadastrado com sucesso!', 'success');
            } else {
                super.showMessage('Sucesso', 'Banner alterado com sucesso!', 'success');
            }
        }
    }

    updateList() {
        this.actionList();
        super.closeModal();
        this.actionClear();
    }

    actionUploadQrCode(type) {
        this.bannerService.upload(this.fileQrCode, `/banner/${this.modelList[0].id}/upload/qr`).subscribe({
            next: data2 => {
                this.updateList();
                if (type === 'create') {
                    super.showMessage('Sucesso', 'Banner cadastrado com sucesso!', 'success');
                } else {
                    super.showMessage('Sucesso', 'Banner alterado com sucesso!', 'success');
                }
            }, error: err => super.onError(err)
        });
    }

    actionClear(): void {
        this.modelFather = new Banner();
        this.modelList = [];
        this.modelIndex = 0;
        this.file = null;
        this.thumpPath = '';
        this.qrCode = '';
    }

    actionSelectModel(index: number): void {
        this.modelIndex = index;
    }
}
