<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<section class="container">

    <div class="d-flex align-items-center ml-5 mt-4">
        <svg-icon class="svg" src="assets/svg/connection.svg"></svg-icon>
        <h1 class="title ml-4">Empresas - Conexão</h1>
    </div>

    <div class="row">
        <div class="col-sm-12 text-right">
            <a (click)="actionCreate()" href="javascript:void('');"
               class="btn btn-ico btn-sm  mt-3 mt-lg-0 btn-success mx-2 mb-3 mb-xs-0">
                <svg-icon class="svg-white" src="assets/svg/add.svg"></svg-icon>
                Nova Empresa
            </a>
        </div>
    </div>

    <div class="card mt-3 bg-white mx-2 mb-4">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12 col-md-8 col-lg-6">
                    <div class="input-material">
                        <input id="name" name="name" [(ngModel)]="modelFilter.name" class="form-control" type="text"
                               maxlength="200" autocomplete="off"
                               required/>
                        <label for="name">Nome</label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-6">
                    <div class="input-material">
                        <input id="description" name="description" [(ngModel)]="modelFilter.description"
                               class="form-control" type="text"
                               maxlength="200" autocomplete="off"
                               required/>
                        <label for="description">Descrição</label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="input-material">
                        <select id="highlight" name="highlight" [(ngModel)]="modelFilter.highlight"
                                class="form-control">
                            <option [ngValue]="undefined">Todos</option>
                            <option [ngValue]="true">Ativo</option>
                            <option [ngValue]="false">Inativo</option>
                        </select>
                        <label for="highlight" class="mb-0 mr-3">Status</label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="input-material">
                        <select id="tag" name="tag" [(ngModel)]="modelFilter.tagId"
                                class="form-control">
                            <option [ngValue]="undefined">Todas</option>
                            <option *ngFor="let t of tags" value="{{t.id}}">{{t.name}}</option>
                        </select>
                        <label for="tag" class="mb-0 mr-3">Tag</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-sm-12 text-right">
                    <a href="javascript:void('');" class="btn btn-ico btn-sm btn-info mx-2 mb-3 mb-xs-0"
                       (click)="getExcel()">
                        <svg-icon class="svg-white" src="assets/svg/excel.svg"></svg-icon>
                        Exportar Excel
                    </a>
                    <a href="javascript:void('');" (click)="actionClearFilter()"
                       class="btn btn-ico btn-sm btn-danger mx-2 mb-3 mb-xs-0">
                        <svg-icon class="svg-white" src="assets/svg/delete.svg"></svg-icon>
                        Limpar Filtro
                    </a>
                    <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0"
                       (click)="page = 1;actionList()">
                        <svg-icon class="svg-white" src="assets/svg/search.svg"></svg-icon>
                        Buscar
                    </a>
                </div>
            </div>
        </div>
    </div>


    <div class="row" *ngIf="result?.results.length === 0">
        <div class="col-12">
            <p class="my-5 py-5 text-center">Nenhum registro encontrado.</p>
        </div>
    </div>
    <div class="row">
        <div *ngFor="let r of result?.results |
                                paginate: {
                                 itemsPerPage: result.pageSize,
                                 currentPage: result.currentPage,
                                 totalItems: result.totalCount}" class="col-md-6 col-xl-4">
            <div class="card mt-3 p-3 bg-white">
                <div class="d-flex flex-column justify-content-around align-items-start">
                    <div class="banner_thumb">
                        <img src="{{!isNullOrUndefined(r.icon)?r.icon:'assets/images/no_image.jpg'}}" alt="img">
                    </div>
                    <div class="enterprise_info mt-4 flex-grow-1">
                        <div class="row">
                            <div class="col-12">
                                <b>Nome:</b>
                                <p class="ml-1">{{r.name}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <b>Tag:</b>
                                <p class="ml-1">{{r.tag.name}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <b>Status:</b>
                                <p class="ml-1">{{r.highlight ? 'Ativo' : 'Inativo'}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <a href="javascript:void('');"
                       class="btn btn-ico btn-ico-no-marg btn-sm btn-warning mx-2 mb-3 mb-xs-0"
                       (click)="actionDetail(r.id)">
                        <svg-icon class="svg-white" src="assets/svg/edit.svg"></svg-icon>
                    </a>
                    <div class="dropdown show">
                        <a href="javascript:void('');"
                           class="btn btn-ico btn-ico-no-marg btn-sm btn-danger mx-2 mb-3 mb-xs-0 dropdown-toggle"
                           role="button" id="dropdownMenuLink" data-toggle="dropdown"
                           aria-haspopup="true" aria-expanded="false">
                            <svg-icon class="svg-white" src="assets/svg/delete.svg"></svg-icon>
                        </a>

                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <a href="javascript:void('');"
                               class="dropdown-item" *ngFor="let it of r.languages"
                               (click)="actionRemove(r.id, it.languageId)">
                                {{getLanguageName(it.languageId, this.languages)}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mt-3 mx-2 bg-white p-3">
        <div class="text-center">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</section>


<div class="modal fade" id="modalCompanyEdit" tabindex="-1" role="dialog" aria-labelledby="moreLabel"
     aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <strong *ngIf="!isNullOrUndefined(modelFather?.id)" class="text-primary">Editar Empresa</strong>
                    <strong *ngIf="isNullOrUndefined(modelFather?.id)" class="text-primary">Cadastrar Empresa</strong>
                </h3>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg-icon class="svg-icon-primary" src="assets/svg/fechar.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body  px-0 pb-0">
                <div class="card mt-3 bg-white mx-2">
                    <div class="card-body">
                        <div class="row justify-content-center d-flex flex-column align-items-center mt-2 mb-2">
                            <div class="banner_thumb small mr-3" *ngIf="!isNullOrUndefined(thumpPath)">
                                <img class="fit-contain" [src]="thumpPath" alt="">
                            </div>
                            <svg-icon *ngIf="!isNullOrUndefined(thumpPath)" class="editIcon editPhoto"
                                      src="assets/svg/edit.svg"></svg-icon>
                            <input type="file" class="BannerFile" onclick="this.value = null"
                                   (change)="handlerUpload($event)">
                            <svg-icon *ngIf="isNullOrUndefined(thumpPath)" class="svg"
                                      src="assets/svg/upload.svg"></svg-icon>
                            <p *ngIf="isNullOrUndefined(thumpPath)" class="text-primary">(Uploads de imagens de até
                                5mb)</p>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <div class="input-material">
                                    <select [(ngModel)]="modelFather.highlight" id="highlightEdit"
                                            class="form-control"
                                            name="highlight">
                                        <option selected [ngValue]="true">Sim</option>
                                        <option selected [ngValue]="false">Não</option>
                                    </select>
                                    <label for="highlight" class="mb-0 mr-3">Ativo?</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <div class="input-material">
                                    <input [(ngModel)]="modelFather.name" id="nameEdit" name="name"
                                           class="form-control"
                                           type="text" maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="name">Nome</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <div class="input-material">
                                    <select id="tagEdit" name="tagEdit" [(ngModel)]="modelFather.tagId"
                                            class="form-control">
                                        <option *ngFor="let t of tags" value="{{t.id}}">{{t.name}}</option>
                                    </select>
                                    <label for="tagEdit" class="mb-0 mr-3">Tag</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <ul class="col-12 d-flex justify-content-center nav nav-tabs mb-3">
                                <li class="nav-item" *ngFor="let m of modelList; let i = index">
                                    <a class="nav-link pe-auto cursor-pointer" [ngClass]="{'active': modelIndex === i}"
                                       (click)="actionSelectModel(i)">{{getLanguageName(m.languageId, languages)}}</a>
                                </li>
                            </ul>
                            <div *ngIf="!isNullOrUndefined(modelList)" class="col-12 mb-12">
                                <div class="row">
                                    <div *ngIf="isNullOrUndefined(modelList[modelIndex].id)"
                                         class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="d-flex" style="gap: 15px; align-items: center;">
                                            <label class="mb-0 duk-check">
                                                <input [(ngModel)]="modelList[modelIndex].activate"
                                                       [ngModelOptions]="{standalone: true}"
                                                       id="activeCreate"
                                                       name="activeCreate"
                                                       type="checkbox"/>
                                                <span class="checkmark">
                                                    <svg-icon src="assets/svg/check.svg"></svg-icon>
                                                </span>
                                                <span>Ativar</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="input-material">
                                            <input [(ngModel)]="modelList[modelIndex].video" name="video" id="video"
                                                   class="form-control"
                                                   type="text"
                                                   maxlength="200"
                                                   [disabled]="!modelList[modelIndex].activate"
                                                   autocomplete="off"/>
                                            <label for="video">Vídeo</label>
                                        </div>
                                        <span id="product" class="badge-rounded"> ? </span>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="input-material">
                                        <textarea [(ngModel)]="modelList[modelIndex].description"
                                                  name="textEdit"
                                                  id="textEdit"
                                                  class="form-control"
                                                  [disabled]="!modelList[modelIndex].activate"
                                                  type="text" maxlength="500" rows="3"
                                                  autocomplete="off" required></textarea>
                                            <label for="textEdit">Descrição</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-sm-12 text-right">
                                <a href="javascript:void('');" (click)="actionSave()"
                                   class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0">
                                    <svg-icon class="svg-white" src="assets/svg/disquet.svg"></svg-icon>
                                    Salvar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
