<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>
<section class="container">
    <div class="d-flex align-items-center ml-5 mt-4">
        <svg-icon class="svg" src="assets/svg/user.svg"></svg-icon>
        <h1 class="title ml-4">Usuários</h1>
    </div>

    <div class="row">
        <div class="col-sm-12 text-right">
            <a (click)="actionCreate()" href="javascript:void('');"
               class="btn btn-ico btn-sm btn-success mx-2 mt-3 mt-lg-0 mb-3 mb-xs-0">
                <svg-icon class="svg-white" src="assets/svg/add.svg"></svg-icon>
                Novo Usuário
            </a>
        </div>
    </div>

    <div class="mt-3">

        <div class="d-sm-flex align-items-center justify-content-between mt-4 mx-4">
            <strong class="subTitle"></strong>
        </div>

        <div class="card mt-3 bg-white mx-2 mb-4">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="name" name="name" [(ngModel)]="modelFilter.name" class="form-control" type="text"
                                   maxlength="200" autocomplete="off" required/>
                            <label for="name">Nome</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <input id="userEmail" name="userEmail" [(ngModel)]="modelFilter.email" class="form-control"
                                   type="text"
                                   maxlength="200" autocomplete="off"
                                   required/>
                            <label for="userEmail">Email</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4">
                        <div class="input-material">
                            <select id="profile" [(ngModel)]="modelFilter.profile" class="form-control" name="type">
                                <option selected [ngValue]="undefined">Todos</option>
                                <option selected [ngValue]="'admin'">Administrador</option>
                            </select>
                            <label for="profile" class="mb-0 mr-3">Perfil</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-info mx-2 mb-3 mb-xs-0"
                           (click)="getExcel()">
                            <svg-icon class="svg-white" src="assets/svg/excel.svg"></svg-icon>
                            Exportar Excel
                        </a>
                        <a href="javascript:void('');" (click)="actionClearFilter()"
                           class="btn btn-ico btn-sm btn-danger mx-2 mb-3 mb-xs-0">
                            <svg-icon class="svg-white" src="assets/svg/delete.svg"></svg-icon>
                            Limpar Filtro
                        </a>
                        <a href="javascript:void('');" class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0"
                           (click)="page = 1;actionList()">
                            <svg-icon class="svg-white" src="assets/svg/search.svg"></svg-icon>
                            Buscar
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-3 mx-2 bg-white p-3">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th class="align-middle text-center" scope="col">Nome</th>
                        <th class="align-middle text-center" scope="col">E-mail</th>
                        <th class="align-middle text-center" scope="col">Perfil</th>
                        <th colspan="2" class="w-10"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="isNullOrUndefined(result?.results)">
                        <td colspan="4">
                            <p class="pt-5 pb-5 text-center">
                                Nenhum registro encontrado.
                            </p>
                        </td>
                    </tr>
                    <tr *ngFor="let r of result?.results |
                                paginate: {
                                 itemsPerPage: result.pageSize,
                                 currentPage: result.currentPage,
                                 totalItems: result.totalCount}">
                        <td class="text-center">{{r.name}}</td>
                        <td class="text-center">{{r.email}}</td>
                        <td class="text-center">{{getProfile(r.profile)}}</td>
                        <td class="text-center">
                            <a href="javascript:void('');"
                               class="btn btn-ico btn-ico-no-marg btn-sm btn-warning mx-2 mb-3 mb-xs-0"
                               (click)="actionDetail(r.id)">
                                <svg-icon class="svg-white" src="assets/svg/edit.svg"></svg-icon>
                            </a>
                        </td>
                        <td class="text-center">
                            <a href="javascript:void('');"
                               class="btn btn-ico btn-ico-no-marg btn-sm btn-danger mx-2 mb-3 mb-xs-0"
                               (click)="actionRemove(r.id)">
                                <svg-icon class="svg-white" src="assets/svg/delete.svg"></svg-icon>
                            </a>
                        </td>
                    </tr>
                    <tr *ngIf="isNullOrUndefined(result?.results)">
                        <td colspan="4">
                            <p class="pt-5 pb-5 text-center">
                                Nenhum registro encontrado.
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-center">
                <pagination-controls
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
        </div>

    </div>
</section>

<div class="modal fade" id="modalUserEdit" tabindex="-1" role="dialog" aria-labelledby="moreLabel"
     aria-hidden="true"
     data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    <strong *ngIf="!isNullOrUndefined(model?.id)" class="text-primary">Editar Usuário</strong>
                    <strong *ngIf="isNullOrUndefined(model?.id)" class="text-primary">Cadastrar Usuário</strong>
                </h3>
                <button type="button" class="close" (click)="model = null;" data-dismiss="modal" aria-label="Close">
                    <svg-icon class="svg-icon-primary" src="assets/svg/fechar.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body  px-0 pb-0">
                <div class="card mt-3 bg-white mx-2">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="input-material">
                                    <input id="nameEdit" [(ngModel)]="model.name" name="name" class="form-control"
                                           type="text"
                                           maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="nameEdit">Nome</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <div class="input-material">
                                    <input id="email" [(ngModel)]="model.email" class="form-control" type="text"
                                           maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="email">E-mail</label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <div class="input-material">
                                    <select id="profileEdit" [(ngModel)]="model.profile" class="form-control"
                                            name="type">
                                        <option selected [ngValue]="'admin'">Administrador</option>
                                    </select>
                                    <label for="profileEdit" class="mb-0 mr-3">Perfil</label>
                                </div>
                            </div>
                            <div *ngIf="isNullOrUndefined(model?.id)" class="col-sm-12 col-md-6 col-lg-6">
                                <div class="input-material">
                                    <input id="password" [(ngModel)]="model.password" class="form-control"
                                           type="password"
                                           maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="password">Senha</label>
                                </div>
                            </div>
                            <div *ngIf="isNullOrUndefined(model?.id)" class="col-sm-12 col-md-6 col-lg-6">
                                <div class="input-material">
                                    <input id="confirmPassword" [(ngModel)]="model.confirmPassword" class="form-control"
                                           type="password"
                                           maxlength="200"
                                           autocomplete="off" required/>
                                    <label for="confirmPassword">Confirmação de Senha</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-sm-12 text-right">
                                <a href="javascript:void('');" (click)="actionSave()"
                                   class="btn btn-ico btn-sm btn-primary mx-2 mb-3 mb-xs-0">
                                    <svg-icon class="svg-white" src="assets/svg/disquet.svg"></svg-icon>
                                    Salvar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
