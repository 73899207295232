import {User} from './user/user';

export class CommentModel {
    id: string;
    name: string;
    jobRole: string;
    companyName: string;
    text: string;
    photo: string;
    video: string;
    languageId: string;
    highlight: boolean;
    createdAt: Date;
    createdById: string;
    updatedAt: Date;
    deletedAt: Date;
    createdBy: User;
    languages: CommentModel[];
    activate: boolean;

    constructor() {
    }
}

export class CommentSearchResult {
    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPage: number;
    previousPage: boolean;
    nextPage: boolean;
    results: CommentModel[];

    constructor() {
        this.results = [];
    }
}

export class FilterComment {
    name: string;
    language: string;
    highlight: boolean;
    start: Date;
    end: Date;
    tags: string[]

    constructor() {
        this.tags = [];
    }
}
