export class Challenge {
    id: string;
    title: string;
    description: string;
    photo: string;
    start: Date;
    end: Date;
    video: string;
    tagId: string;
    highlight: boolean;
    languageId: string;
    problem: string;
    objective: string;
    createdAt: Date;
    updatedAt: Date;
    tag: Tag;

    constructor() {
    }
}

export class TagSearchResult {
    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPage: number;
    previousPage: boolean;
    nextPage: boolean;
    results: Tag[];

    constructor() {
        this.results = [];
    }
}

export class FilterTag {
    name: string;
    types: Tag[];

    constructor() {
        this.types = [];
    }
}

export class Tag {
    id: string;
    languageId: string;
    name: string;
    color: string;
    types: string[];
    languages: Tag[];
    activate: boolean;
}

export class TagType {
    name: string;
    description: string;
}
