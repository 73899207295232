import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {Challenge, ChallengeSearchResult, FilterChallenge, Tag} from '../../model/challenge';
import {ChallengeService} from '../../service/challenge.service';
import {environment} from '../../../environments/environment';
import {LanguageModel} from '../../model/common_model';
import {CommonService} from '../../service/common.service';
import {TranslateService} from '@ngx-translate/core';
import {ExcelService} from "../../service/excel.service";

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-challenges',
    templateUrl: './challenges.component.html',
    styleUrls: ['./challenges.component.scss']
})
export class ChallengesComponent extends BaseComponent implements OnInit, AfterViewInit {

    result: ChallengeSearchResult = new ChallengeSearchResult();
    modelFilter: FilterChallenge = new FilterChallenge();
    modelList: Challenge[] = [];
    modelFather: Challenge = new Challenge();
    modelIndex = 0;
    page = 1;
    file: File = null;
    thumpPath: string | ArrayBuffer;
    languages: LanguageModel[] = [];
    listTags: Tag[] = [];

    constructor(public router: Router,
                public challengeService: ChallengeService,
                public commonService: CommonService,
                public translate: TranslateService,
                public excelService: ExcelService) {
        super(router, translate, 'modalChallengeEdit');
    }

    ngOnInit(): void {
        //this.setDatePicker();
        this.actionList();
        this.getTags();
        this.getLanguages();
        $('[data-toggle="popover"]').popover({
            html: true,
            trigger: 'focus'
        });
    }

    ngAfterViewInit(): void {
        this.popoverStruct('product', 'Link do video', 'Este campo devera conter o link do video do youtube, como no exemplo a seguir.');
    }

    popoverStruct(id: string, title: string, content: string): any {
        return $('#' + id).popover({
            title: '',
            content: 'That',
            trigger: 'hover',
            placement: 'right',
            template: '<div class="popover alert-error">' +
                '<div class="arrow"></div>' +
                '<div class="popover-inner">' +
                '<div class="popover-content">' +
                '<div class="border-right-custom p-2">' +
                '<p class="mb-0 text-uppercase font-weight-bold size-12">' + title + '</p>' +
                '<p class="info size-10"> ' + content + ' </p>' +
                '<img src="assets/images/video.jpg"> ' +
                '</div>' +
                '</div>' +
                '</div>' +
                '</div>',
        });
    }

    setDatePicker(): void {
        /*this.modelFilter.start = moment().subtract(30, 'days').format();
        this.modelFilter.end = moment().format();
        $('.js-daterangepicker').daterangepicker({
            showDropdowns: false,
            startDate: moment().subtract(30, 'days'),
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.modelFilter.start = start.format();
            this.modelFilter.end = end.format();
        });*/
    }

    setDatePickerEdit(): void {
        const dateStart = this.isNullOrUndefined(this.modelFather.start) ? moment() : moment(this.modelFather.start).format('DD/MM/yyyy');
        const dateEnd = this.isNullOrUndefined(this.modelFather.end) ? moment() : moment(this.modelFather.end).format('DD/MM/yyyy');

        $('.js-daterangepicker-edit').daterangepicker({
            showDropdowns: false,
            startDate: dateStart,
            endDate: dateEnd,
            locale: super.objLocaleDateRangePicker()
        }, (start, end, label) => {
            this.modelFather.start = start.format();
            this.modelFather.end = end.format();
        });
    }

    getLanguages(): void {
        this.commonService.getLanguages().subscribe({
            next: data => {
                this.languages = data as LanguageModel[];
            }, error: err => super.onError(err)
        });
    }

    getTags(): void {
        this.commonService.getTagsChallenge().subscribe({
            next: data => {
                this.listTags = data as Tag[];
            }, error: err => super.onError(err)
        });
    }

    actionList(): void {
        this.challengeService.search(this.page, this.modelFilter).subscribe({
            next: data => {
                this.result = data as ChallengeSearchResult;
            }, error: err => super.onError(err)
        });
    }

    pageChanged(event): void {
        this.page = event;
        this.actionList();
    }

    getExcel(): void {
        this.excelService.getExcelChallenge(this.modelFilter).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }

    actionDetail(id: string): void {
        this.actionClear();
        this.challengeService.get(id).subscribe({
            next: data => {
                this.modelList = data as Challenge[];
                this.thumpPath = this.modelList[0].photo;
                this.modelFather = this.modelList[0];
                this.setDatePickerEdit();
                super.normalizeModels(this.modelList, this.languages);
                this.showModal();
            }, error: err => super.onError(err)
        });
    }

    actionSave(): void {
        let arrValidateFields = [];

        if (super.isNullOrUndefined(this.modelFather.id)) {
            arrValidateFields = [
                {value: this.modelFather.tagId, text: 'Tag,<br>'},
                {value: this.modelFather.start, text: 'Começo,<br>'},
                {value: this.modelFather.end, text: 'Fim,<br>'},
                {value: this.modelFather.link, text: 'Link,<br>'},
                {value: this.file, text: 'Selecione um arquivo <br>'}
            ];
        } else {
            arrValidateFields = [
                {value: this.modelFather.tagId, text: 'Tag,<br>'},
                {value: this.modelFather.start, text: 'Começo,<br>'},
                {value: this.modelFather.end, text: 'Fim,<br>'},
            ];
        }

        for (const m of this.modelList.filter(x => x.activate)) {
            arrValidateFields.push({
                value: m.title,
                text: `Título ${this.getLanguageName(m.languageId, this.languages)}, <br>`
            });
            arrValidateFields.push({
                value: m.description,
                text: `Descrição ${this.getLanguageName(m.languageId, this.languages)}, <br>`
            });
            arrValidateFields.push({
                value: m.problem,
                text: `Problema ${this.getLanguageName(m.languageId, this.languages)}, <br>`
            });
            arrValidateFields.push({
                value: m.objective,
                text: `Objetivo ${this.getLanguageName(m.languageId, this.languages)}, <br>`
            });
        }

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage('Atenção', `Preencha os campos corretamente: <br><br> ${stringError}`, 'warning');
            return;
        }

        for (const m of this.modelList) {
            m.start = this.modelFather.start;
            m.end = this.modelFather.end;
            m.tagId = this.modelFather.tagId;
            m.link = this.modelFather.link;
        }

        if (this.isNullOrUndefined(this.modelFather.id)) {
            if (this.modelList.filter(x => x.activate).length === 0) {
                this.showMessage('Atenção', `Preencha ao menos 1 idioma`, 'warning');
                return;
            }
            this.create();
        } else {
            this.update();
        }
    }

    create(): void {
        this.challengeService.create(this.modelList.filter(x => x.activate)).subscribe({
            next: data => {
                this.modelList = data as Challenge[];
                this.actionUploadImage('create');
            }, error: err => super.onError(err)
        });
    }

    update(): void {
        this.challengeService.update(this.modelList.filter(x => x.activate), this.modelFather.id).subscribe({
            next: data => {
                this.modelList = data as Challenge[];
                if (!super.isNullOrUndefined(this.file)) {
                    this.actionUploadImage('update');
                } else {
                    super.showMessage('Sucesso', 'Desafio alterado com sucesso!', 'success');
                    this.actionList();
                    this.actionClear();
                    super.closeModal();
                }
            }, error: err => super.onError(err)
        });
    }

    actionRemove(id: any, lang: string): void {
        super.confirmMessage('Atenção', `Deseja realmente remover este o desafio ${super.getLanguageName(lang, this.languages)}?`, () => {
            this.challengeService.delete(id, lang).subscribe({
                next: data => {
                    this.actionList();
                    super.showMessage('Sucesso', 'Desafio removido com sucesso!', 'success');
                }, error: err => super.onError(err)
            });
        });
    }

    actionCreate(): void {
        this.actionClear();

        for (const l of this.languages) {
            const c = new Challenge();
            c.languageId = l.key;
            c.activate = false;
            this.modelList.push(c);
        }

        super.showModal();
    }

    handlerUpload(event): void {
        const files = event.target.files;
        if (files.length > 0) {
            if (files[0].size > 5000000) {
                super.showMessage('Atenção', 'O arquivo não deve ultrapassar o tamanho de 5MB', 'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (file) => {
            this.thumpPath = file.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);

        this.file = files[0];
    }

    actionUploadImage(type): void {
        if (!super.isNullOrUndefined(this.file)) {
            this.challengeService.upload(this.file, this.modelList[0].id).subscribe({
                next: data => {
                    super.closeModal();
                    this.actionList();
                    this.actionClear();

                    if (type === 'create') {
                        super.showMessage('Sucesso', 'Desafio cadastrado com sucesso!', 'success');
                    } else {
                        super.showMessage('Sucesso', 'Desafio alterado com sucesso!', 'success');
                    }
                }, error: err => super.onError(err)
            });
        } else {
            super.closeModal();
            this.actionList();
            this.actionClear();

            if (type === 'create') {
                super.showMessage('Sucesso', 'Desafio cadastrado com sucesso!', 'success');
            } else {
                super.showMessage('Sucesso', 'Desafio alterado com sucesso!', 'success');
            }
        }
    }

    actionClearFilter(): void {
        this.modelFilter = new FilterChallenge();
        //this.setDatePicker();
        this.actionList();
    }

    actionSelectModel(index: number): void {
        this.modelIndex = index;
    }

    actionClear(): void {
        this.modelFather = new Challenge();
        this.modelList = [];
        this.modelIndex = 0;
        this.file = null;
        this.thumpPath = '';
        this.setDatePickerEdit();
    }
}
