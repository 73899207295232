import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends BaseComponent implements OnInit, AfterViewInit {

    navBarShort = false;

    constructor(public router: Router,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.handlerCollapse();

    }

    ngAfterViewInit(): void {
        this.navActive();
    }

    handlerCollapse(): void {
        $('.navbar-nav .nav-item').click(function () {
            $('.navbar-nav .nav-item').removeClass('active');
            $(this).addClass('active');
        });
        $('.navbar-nav .nav-link').click(function () {
            $('.navbar-nav .nav-link').removeClass('text-white');
            $(this).addClass('text-white');
        });
    }

    actionCloseCollapse(): void {
        this.actionSideBar();
        $('#reports').collapse('hide');
        $('#help').collapse('hide');
        $('body').removeClass('nav-open');
        $('.hamburger').removeClass('open');
    }

    navActive(): void {
        if (this.router.url === '/calibration' || this.router.url === '/engines') {
            return this.handlerNavActive('.report-ts');
        }
        if (this.router.url === '/training' || this.router.url === '/faq') {
            return this.handlerNavActive('.help-ts');
        }
    }

    handlerNavActive(el): void {
        $('.navbar-nav .nav-item').removeClass('active');
        $('.navbar-nav .nav-link').removeClass('text-white');
        $(el).addClass('active');
        $(el + ' .nav-link').addClass('text-white');
    }

    actionSideBar(): void {
        this.navBarShort = !this.navBarShort;
    }
}
