import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BannerService extends ServiceBase {
    constructor(http: HttpClient) {
        super(http);
    }

    get(id: string): any {
        const url = `${this.BASE_URL}/banner/${id}`;
        return this.http.get(url);
    }

    search(page: number = 1, model): any {
        const url = `${this.BASE_URL}/banner/search/${page}`;
        return this.http.post(url, model);
    }

    create(model): any {
        const url = `${this.BASE_URL}/banner/create`;
        return this.http.post(url, model);
    }

    update(model, id): any {
        const url = `${this.BASE_URL}/banner/update/${id}`;
        return this.http.put(url, model);
    }

    delete(id: string, lang: string): any {
        const url = `${this.BASE_URL}/banner/${id}/${lang}`;
        return this.http.delete(url);
    }

    upload(file, u): any {
        const url = `${this.BASE_URL}` + u;
        return new Observable((observer) => {
            const formData: FormData = new FormData();
            const xhr: XMLHttpRequest = new XMLHttpRequest();

            if (file != null) {
                formData.append('file', file, file.name);
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
            xhr.send(formData);
        });
    }
}
