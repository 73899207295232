import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {User} from '../../model/user/user';
import {UserService} from '../../service/user.service';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseComponent implements OnInit, AfterViewInit {

    constructor(public router: Router,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        moment.locale('pt-br');
        setTimeout(() => {
            $('#loaderBox').fadeOut();
        }, 1000);
        this.userService.checkAuthenticated();
        if (this.userService.isAuthenticated) {
            this.userService.getMe().subscribe(
                data => {
                    const a = data as User;
                    this.userService.updateUserDataSource(a);
                    super.toggleLoader(false);
                },
                error => {
                    this.onError(error);
                    localStorage.clear();
                    this.userService.isAuthenticated = false;
                    super.toggleLoader(false);
                    this.router.navigate(['/login']);
                }
            );
        } else {
            super.toggleLoader(false);
            this.router.navigate(['/login']);
        }
    }

    ngAfterViewInit(): void {
        $(document).on('show.bs.modal', '.modal', function () {
            $(this).appendTo('body');
        });
    }

}
