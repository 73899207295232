export class Banner {
    id: string;
    title: string;
    text: string;
    image: string;
    qrCode: string;
    video: string;
    languageId: string;
    highlight: boolean;
    createdAt: Date;
    activate: boolean;
    languages: Banner[];
}

export class BannerSearchResult {
    totalCount: number;
    pageSize: number;
    currentPage: number;
    totalPage: number;
    previousPage: boolean;
    nextPage: boolean;
    results: Banner[];

    constructor() {
        this.results = [];
    }
}

export class FilterBanner {
    title: string;
    language: string;
    highlight: boolean;
    start: Date;
    end: Date;
}
