import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {UserService} from '../../service/user.service';
import {User} from '../../model/user/user';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {

    model: User = new User();

    constructor(public router: Router,
                public translate: TranslateService,
                public userService: UserService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.model = data as User;
            }, error: err => super.onError(err)
        });
    }

    actionNavToggle(): void {
        $('body').toggleClass('nav-open');
        $('.hamburger').toggleClass('open');
    }
}
